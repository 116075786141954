import React, { useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Whichtime from './whichtime';
import './booking.css'
import { bookAppointment } from '../../../redux/actions/bookappoinmentactions';
import { useDispatch } from 'react-redux';
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
      slidesToSlide: 1 
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 3,
      slidesToSlide: 1 
    },
    smallscreen:{
      breakpoint: { max: 500, min: 0 },
      items: 2,
      slidesToSlide: 1 
    }
  };
  const CustomLeftArrow = ({ onClick}) => {   
    return (
      <button onClick={onClick} className=' absolute top-7 left-0 z-20 flex justify-center items-center ' >
        <IoIosArrowBack className='text-[#46484B] font-bold text-xl' />
      </button>
    );
  };
  const CustomRightArrow = ({ onClick }) => {
    return (
      <button onClick={onClick} className='  absolute top-7 right-0 z-20 flex justify-center items-center '  >
        <IoIosArrowForward className='text-[#46484B] font-bold text-xl' />
      </button>
    );
  };
const Bookingtime = ({slots,doctorId,hospitalAvailableId,loading,hospitalfee,hospitalname}) => {
  const [show,setShow]=useState(0);
  const dispatch = useDispatch();  
  const handleclick= (index) => {
    const selectedDay = days[index];
    let selectedDate;

    if (index === 0) {
        selectedDate = new Date();
    } else if (index === 1) {
        selectedDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    } else {
        selectedDate = new Date(currentDate.getTime() + (index * 24 * 60 * 60 * 1000));
    }
    const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    setShow(index);
    dispatch(bookAppointment({ 
      date: formattedDate, 
      doctorId, 
      hospitalAvailableId, 
      hospitalfee, 
      hospitalname 
    }));
};



  
    const days=[];
    let currentDate=new Date();
    days.push('Today');
    let tomorrow=new Date(currentDate.getTime()+24*60*60*1000);
    let tomorrowDate= tomorrow.getDate();
    let tomorrowMonth=tomorrow.toLocaleString('en-US',{ month: 'short'})
    days.push(`Tomorrow`)
    for (let i = 2; i < 14; i++) {
      let day = new Date(currentDate.getTime() + (i * 24 * 60 * 60 * 1000));
      let dayName = day.toLocaleString('en-US', { weekday: 'short' });
      let dayDate = day.getDate();
      let dayMonth = day.toLocaleString('en-US', { month: 'short' });
      days.push({ dayName, dayDate, dayMonth });
    }


    // const nextAvailableDay = days.find((day, index) => {
    //   const formattedDate = `${day.dayDate} ${day.dayMonth}`;
    //   const availableSlots = slots.filter((slot) => slot.date === formattedDate);
    //   return availableSlots.length > 0;
    // });

  return (
    <>
    <div className=' w-11/12 md:w-4/5 xl:w-3/5   rounded-md mb-8 mx-auto bg-white shadow-md px-5 py-5'>
        <Carousel 
                axis='horizontal'
            showStatus={false}
            responsive={responsive}
            customLeftArrow={ <CustomLeftArrow  />}
            customRightArrow={ <CustomRightArrow  />} 
            infinite={true}
            className='w-full mx-auto  h-20  cursor-pointer flex    '>
      {days.map((day, index) => {
        if (index < 2) {
          return (
            <div
              key={index}
              className={`text-center   flex flex-col  pt-3  w-40 h-16 mx-4  text-sm ${index===show ? 'actives':''} }`}
               onClick={()=>handleclick(index)}
            >
              <p>{day}</p>
              {index === 0 ? `${currentDate.getDate()} ${currentDate.toLocaleString('en-US', { month: 'short' })}` : index === 1 ? `${tomorrowDate} ${tomorrowMonth}` : `${day.dayDate} ${day.dayMonth}`}
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className={`text-center flex flex-col  pt-3  w-40 h-16 mx-4 text-sm ${index===show ? 'actives':''}`}    onClick={()=>handleclick(index)}>
              <p>{day.dayName}</p>
              <h1>{day.dayDate + ' ' + day.dayMonth}</h1>
            </div>
          );
        }
      })}
       </Carousel>
       {show !== null && (
  <Whichtime
    daybooking={
      days[show].dayDate ? days[show].dayDate : 
      (show === 0 ? currentDate.getDate() : tomorrowDate)
    }
    monthbooking={show < 2 ? (show === 0 ? currentDate.toLocaleString('en-US', { month: 'short' }) : tomorrow.toLocaleString('en-US', { month: 'short' })) : days[show].dayMonth}
    slots={slots}
    loading={loading}
    // nextAvailableDay={nextAvailableDay}
    // handleclick={handleclick }
  />
)}
    </div>
    </>
  )
}
export default Bookingtime
