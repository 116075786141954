import React, { useState } from 'react'
import { FaCheck } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";
import './appoinetmentfor.css';
const   AppoinetmentFor= ({phonenumber,setShowpopup,pateinname,setPateintName,setAppoinetmentselection,setPayment,addpateintname,pateinterror,appointmenterror,paymenterror}) => {
const [showactive,setShowactive]=useState(null);
const [appoinetmentactive,setAppoinetmentactive]=useState(null);
const handleclik=(index)=>{
    setShowactive(index)
    setPayment(index)
}
const handleclikappont=(index)=>{
    setAppoinetmentactive(index)
    setAppoinetmentselection(index)
} 
const handleclicksomeone=()=>{
  setShowpopup(true);
}
const handlepateintName=(e)=>{
  setPateintName(e.target.value);
}
  return (
       <>
       <div className='w-full box  rounded py-10 bg-white '>
       <div className='flex flex-col space-y-5 '>
          <div className='flex flex-col space-y-4 px-10 '>
              <div className='text-lg text-[#232426] font-medium'>
              Appointment For
              </div>
              <div className='flex flex-row space-x-5 cursor-pointer'>
              <div className='flex text-[15px] items-center space-x-1 bg-[#4C63BD4D] px-2 py-1 rounded-md'>
                 <span className='text-primary'><FaCheck/></span> <span className='text-[#232426]'>Myself</span>
              </div>
              <div className='flex text-[15px] items-center space-x-1 text-[#46484b] '>
              {addpateintname}

              </div>
              <div className='flex text-[15px] items-center space-x-1 text-[#46484b] text-opacity-70' onClick={handleclicksomeone}>
                 <span className='text-xs '><FaPlus/></span> <span>Someone else</span>
              </div>

              </div>
          </div>
          <div className='flex flex-col space-y-4 w-full '>
          <div className='flex flex-col'>
          <div className='flex flex-row px-2  items-center space-x-3'>
             <div className='text-2xl text-[#46484b] md:text-opacity-50'>
                 <LuUser2/>
             </div>
             <div className='text-lg text-[#232426] font-medium'>
             Patient Name
             </div>
           </div>
           <div className='mx-10'>
              <input type='text' value={pateinname}   onChange={handlepateintName}    className='w-11/12 border-b-[1px] outline-none py-1'  />
              {pateinterror && <p className='text-xs text-[#E6220C]'>{pateinterror}</p>}

           </div>
           
          </div>

        
           <div className='flex flex-col space-y-2 mx-10 '>
               <label className='text-[#46484b]'>Phone Number</label>
              <input type='text'  value={phonenumber}  className='w-11/12 border outline-none py-3 px-3 rounded-md bg-[#F8F9FA]' readOnly required/>
              <p className='flex items-center text-xs space-x-1 text-[#46484b] text-opacity-70'><span><IoIosInformationCircleOutline/></span><span>You will be contacted through this number</span></p>
           </div>
          </div>
  <div className='flex flex-col px-10 space-y-4 '>
  <div className='text-lg text-[#232426] font-medium'>
  Purpose of appointment
  </div>
  <div className='flex flex-col space-y-2 md:space-y-0 space-x-0 md:flex-row w-full md:space-x-5 text-sm  '>
    <div className='w-full md:w-[25%] flex items-center space-x-2' onClick={()=>handleclikappont(1)}>
      <input type='checkbox' id='checkbox1' className='rounded-checkbox'  checked={appoinetmentactive===1} onChange={()=>{}} required={appoinetmentactive===1}/>
      <label For='checkbox1' className='checkbox-label'> Normal Consultation</label>
    </div>
    <div className='w-full md:w-[25%] flex items-center space-x-2' onClick={()=>handleclikappont(2)}>
    <input type='checkbox' id='checkbox2' className='rounded-checkbox' checked={appoinetmentactive===2} onChange={()=>{}} required={appoinetmentactive===2}/>
   <label For='checkbox2' className='checkbox-label'> Surgery related Visit</label>

</div>



  </div>

  {appointmenterror && <p className='text-xs text-[#E6220C]'>{appointmenterror}</p>}

  

  </div>


<div className='flex flex-col space-y-4 w-full '>
<div className='flex flex-row px-2 items-center space-x-3'>
             <div className='text-xl text-[#46484b] text-opacity-70'>
                 <MdOutlinePayment/>
             </div>
             <div className='text-lg text-[#232426] font-medium'>
             Select Payment Method
             </div>
           </div>

        <div className='flex flex-col mx-10   space-y-2'>
       
        <div className={` ${showactive===1 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#4C63BD4D]':'border  text-[#46484b] text-opacity-60 bg-white '} flex items-center justify-between rounded-md px-3 w-11/12  cursor-pointer py-2`} onClick={()=>handleclik(1)}>
        <div className='flex items-center space-x-2 py-2 '>
        <input  type="checkbox" class="rounded-checkbox" id="online-payment" checked={showactive===1}   onChange={()=>{}}/>
        <label for="online-payment " style={{fontSize:'14px'}}>Online payment</label>
        </div>
        <div className='text-sm'>
        Rs. 2,500
        </div>
        </div>

        <div className={` ${showactive===2 ? 'border border-[#4C63BD] text-[#46484b] text-opacity-100 bg-[#4C63BD4D]':'border text-[#46484b] text-opacity-60  bg-white'} flex items-center justify-between rounded-md px-3 w-11/12  cursor-pointer py-2`} onClick={()=>handleclik(2)}>
        <div className='flex items-center space-x-2 py-2 '>
        <input  type="checkbox" class="rounded-checkbox" id="online-payment" checked={showactive===2} required={showactive===2}  onChange={()=>{}} />
        <label for="online-payment " style={{fontSize:'14px'}}>Pay Cash at clinic</label>
        </div>
        <div className='text-sm'>
        Rs. 2,500
        </div>
       

        </div>

        {paymenterror && <p className='text-xs text-[#E6220C]'>{paymenterror}</p>}

     
            
        </div>
           

</div>

         

       </div>
       </div>

       </>
  )
}

export default   AppoinetmentFor
