import React from 'react'
import Navbar from '../../global/navbar'
import Footer from '../../global/footer/index'
import Main from './navigation/main'
import Stickybar from './navigation/stickybar'
const Userone = () => {
  return (
    <>
    <div className='bg-[#F8F9FA] lg:bg-white'>
    <div className='navshadow bg-white '>
       <Navbar/>       
       </div>
        <Main />
       <Footer/> 
       <Stickybar/>
       </div>
    </>
  )
}

export default Userone
