import React,{useEffect} from 'react'
import Navbar from '../../components/global/navbar'
import Heading from '../../components/pages/Doctor/heading'
import Doctorlist from '../../components/pages/Doctor/doctorlist'
import Footer from '../../components/global/footer/index'
import Doctorslider from '../../components/pages/Doctor/doctorslider'
import Headroom from 'react-headroom'
 import { useDispatch,useSelector } from 'react-redux';
import {fetchapicatergorycity} from '../../redux/actions/categorycityaction';
 import LoadingImage from '../../assests/Images/globalpics/loadingimg.svg'
import { useParams } from 'react-router-dom'; 

const Doctor = () => {
  const dispatch=useDispatch();
  const{city,category}=useParams()
  const {CityCategory,loading}=useSelector((state)=>state.citycategory)
  useEffect(() => {
    if (city && category) {
      dispatch(fetchapicatergorycity(city, category));
    }
  }, [dispatch, city, category]);
    
  return (
    <>
    <Headroom>
    <div className='navshadow  bg-white ' >
 <Navbar />
 </div>
    </Headroom>

    {CityCategory && CityCategory.length > 0 && (
        <div>
          <Heading doctorctiy={city} doctorcategories={CityCategory[0].categories.filter(cat => cat._id === category)} />
          <div className='sticky top-0 z-50 bg-white shadow-md'>
            <Doctorslider />
          </div>
        </div>
      )}

      {
    loading ? (
      <div className='w-full h-screen flex justify-center items-center'>
        <img src={LoadingImage} alt='Loading' className='w-16 h-16' />
      </div>
    ) : CityCategory && CityCategory.length > 0 ? (
      CityCategory.map((doctor) => (
        <Doctorlist
          key={doctor._id}
          doctorId={doctor._id}
          doctordetails={doctor.bio}
          username={doctor.username}
          yearofexperince={doctor.yearsOfExperience}
          pictue={doctor.image}
          hospital={doctor.hospitals}
        />
      ))
    ) : (
      <div className='w-full h-screen flex justify-center items-center'>
        <p>No doctors found</p>
      </div>
    )
  }




    
  
   
    
      

     
    
  


    <Footer/>
   

    </>
  )
}

export default Doctor
