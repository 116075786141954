import React from 'react'
const Footerlast = () => {
  return (
     <>
        <div className='w-full bg-[#000000]'>
          <div className='w-11/12 mx-auto  text-white '>
            <p className='py-5 text-center text-base  font-semibold'>
            <span>&#169;</span> 2024  All Rights Reserved   Callvet Digital LLC. <span className='text-[--primary] text-base'>Powered By GhairMulki</span></p>
          </div>
        </div>
     </>
  )
}

export default Footerlast
