import {setViewProfile,setLoading,setError} from '../reducers/viewprofilereducer';
import axios from 'axios';
export const fetchDoctorDetails=(id)=>async(dispatch)=>{
    try{
        dispatch(setLoading(true));
        const response=await axios.get(`https://callvet.raabtastore.com/api/doctor/${id}`);
        dispatch(setViewProfile(response.data.data.doc));
    }catch(error){
        console.error('Error fetching doctor details:', error);
        dispatch(setError(error.message))
    }finally{
        dispatch(setLoading(false));
    }
}
