import React, { useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";



const Importantnote = () => {

const [show,setShow]=useState(false)
const showacco=()=>{
    setShow(!show)

}
  return (
     <>
       <div className='w-full rounded-md box py-4 mt-7 bg-white '>
       <div className='flex flex-col space-y-3  cursor-pointer'>
       <div className=' flex justify-between border-b px-4 py-2' onClick={showacco}>
            <p>Important Note</p>
            { show ?  <IoIosArrowDown/>:<IoIosArrowUp/>}
            
          </div>
     <div className='w-full '>
     <ul className='transition-max-height duration-500 overflow-hidden text-sm px-10 text-[#686868]' style={{ maxHeight:  show ?'1000px':'0'}}>
        <li className='list-disc	'>Appointment confirmation details are also sent through SMS.</li>
        <li className='list-disc	' >Please show the confirmation details to either doctor or receptionist when you visit the clinic.</li>
     </ul>
     </div>

        </div>
       </div> 
     </>
  )
}

export default Importantnote
