import { createSlice } from "@reduxjs/toolkit";
const CityReducer=createSlice({
    name:'Citylist',
    initialState:{
      CitySelect:[],
      loading:false,
    },
    reducers:{
        setCityList(state,action){
            state.CitySelect=action.payload;
        },
        setLoading(state,action){
            state.loading=action.payload;
        },

    }


})
export const{setCityList,setLoading}=CityReducer.actions;
export default CityReducer.reducer;