import React, { useState,useContext } from 'react'
import { FaBars } from "react-icons/fa";
import  Logo from '../../../assests/Images/globalpics/Layer 1.svg'
import Toolbars from './toolbars';
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { PatientContext } from '../../../context/pateintcontext';
import { IoIosArrowDown } from "react-icons/io";
import Navtoobar from '../../../assests/Images/globalpics/tooglebar.svg'


const Responsivenavbar = () => {
  const{selectedPatient}=useContext(PatientContext);

const [show,setShow]=useState(false);
const handleShow=()=>{
    setShow(true)
}
  return (
    <>
    <div className=' relative '>
    {
        show && 
         <Toolbars  setShow={setShow}/>
    }
    </div>
    

<div className='w-full block lg:hidden  '>

<div className='flex flex-row sm:justify-between w-full  sm:w-11/12 mx-auto items-center  cursor-pointer py-3 '>
<div className='flex flex-row  space-x-3  w-full  '>
<div className='text-xl cursor-pointer' onClick={handleShow}  >
    <img src={Navtoobar} alt='img' className='h-10 w-10'/>
    </div>
    <div className='w-full sm:w-36 sm:h-9 flex justify-center pr-[50px] sm:flex-none sm:pr-0     '>
   <Link to='/'>
   <img src={Logo} alt='img' className=' w-36 h-full sm:w-full sm:h-full'/>
   </Link> 
    </div>
</div>
    

<div className='hidden sm:block'>
<div className='flex space-x-4'>

                  {

selectedPatient  ? (
  <p className='flex space-x-2 items-center text-[--primary] text-[15px] font-semibold cursor-pointer  '> <span> {selectedPatient} </span> <span><IoIosArrowDown/></span> </p>
):(
  <button className='bg-[#4C63BD] text-white w-[156px] h-[40px] text-[15px]  rounded	 '>Login / Signup</button>
                        
)

}
    <button className='flex flex-row justify-center items-center  text-white space-x-3 w-[156px] h-[40px] bg-[#28B446] text-[15px] rounded	'>
                              <span><FaPhoneAlt/></span>
                              <span>04238900939</span>
                             </button>

    </div>
</div>
    
      
     

      </div>
   
    </div>
    </>
   
      
      
    
  )
}

export default Responsivenavbar
