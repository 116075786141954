import { createContext,useState } from "react"
const PatientContext=createContext();
const PatientProvider = ({children}) => {
    const [selectedPatient, setSelectedPatient] = useState('');
    return (
     <>
       <PatientContext.Provider value={{ selectedPatient, setSelectedPatient }}>
            {children}
       </PatientContext.Provider>
     </>
  )
}

export { PatientContext, PatientProvider };
