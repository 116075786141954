import React, { useEffect, useState } from 'react'
import './banner.css';
import { CiLocationOn } from "react-icons/ci";
import Hand from '../../../assests/Images/homepics/callvet-hero 1.svg';
import { useDispatch,useSelector } from 'react-redux';
import {fetchapiservices} from '../../../redux/actions/servicesaction'
import {fetchapicities} from '../../../redux/actions/citylistaction'
import Loadingimage from '../../../assests/Images/globalpics/loadingimg.svg'
import { useNavigate } from 'react-router-dom';
const Banner = () => {
  const dispatch =useDispatch();
  const navigate = useNavigate();
  const {CitySelect}=useSelector((state)=>state.city);
  const {ServiesDvm,loading}=useSelector((state)=>state.services)
const [show,setShow]=useState(false);
const [filterValue, setFilterValue] = useState('');
const [filterValuecity, setFilterValuecity] = useState('');
const [selectedCity,setSelectedCity]=useState(null);
const [selectCategory,setSelectCategory]=useState(null);

useEffect(()=>{
  dispatch(fetchapicities())
},[dispatch])

useEffect(()=>{
  dispatch(fetchapiservices());
},[dispatch])

const handledropdownsec=(index)=>{
  setShow(index)
}
const handlechange=(e)=>{
  setFilterValuecity(e.target.value);
}
const handlechangepateint=(e)=>{
  setFilterValue(e.target.value);
}

const handleselectcity=(city)=>{
  setSelectedCity(city);
  setFilterValuecity(city.name);
  setShow(null);
}
const handleSelectCategory=(category)=>{
  setSelectCategory(category);
    setFilterValue(category.name);
  setShow(null);

}
const handleshowdoctor = (e) => {
  e.preventDefault();
  if (selectedCity && selectCategory) {
    navigate(`/pages/doctor/${selectedCity.name}/${selectCategory._id}`);
  }
};


const filteredDvms = ServiesDvm.filter(Dvmser => Dvmser.name.toLowerCase().includes(filterValue.toLowerCase()));
const filteredCities=CitySelect.filter(city => city.name.toLowerCase().includes(filterValuecity.toLowerCase()))
  return (
       <div className='w-full  '  >
      <div className='w-11/12  flex flex-col  mx-auto pt-1 xl:pt-10 pb-24 '>
      <div className='flex flex-row w-full 0  mt-8'>
      <div className='w-full sm:w-2/4 py-3 xl:py-0 '  >
      <h1 className='text-2xl sm:text-[26px] md:text-4xl lg:text-[40px] xl:text-[50px] text-primary font-semibold leading-8 sm:leading-9 md:leading-[50px] xl:leading-[75px] pt-5 lg:pt-20 xl:pt-16'>
      Find your desired Vet Doctor Right Now!
      </h1>
      </div>
      <div className='w-0 sm:w-2/4 flex justify-end  relative ' >
      <div className='absolute right-0 sm:bottom-[-58px] md:bottom-[-46px] lg:bottom-[-25px] xl:bottom-0'>
      <img src={Hand} alt='img' className='  sm:w-[300px] md:w-[350px] lg:w-[424px] xl:w-[524px] h-[271px]   hidden sm:block '/>
      </div>
      </div>
      </div>
      <from    >
      <div className='flex flex-col sm:flex-row w-full bg-white h-auto xl:h-14 rounded  items-center  '>
        <div className='w-full sm:w-[35%] h-full border-b-[1px] sm:border-b-0  sm:border-r-2 relative py-3  sm:py-0 sm:rounded-tl sm:rounded-bl '>
        <CiLocationOn className='absolute text-xl top-3 sm:top-[2px] xl:top-4 left-2 text-primary'/>
        <input type='text '  value={filterValuecity} placeholder='City, state,or Zip  code' className='w-full h-full  px-8 outline-none  text-[15px] ' onChange={handlechange}      onClick={()=>handledropdownsec(1)}/>
        {
          show===1 &&
          <div className={`w-full   absolute h-[300px]  top-14 sm:top-16 bg-white cursor-pointer z-50 citylist `}>
        
          <ul>
          {
             loading ?
             (
              <div className='w-full flex justify-center items-center'>
        <img src={Loadingimage} alt='img' className='w-16 h-16'/>
      </div>
             ):
             (
              filteredCities.map(city=>(
                <li className='border-b-2 py-2 px-3 main-color ' key={city._id} onClick={()=>handleselectcity(city)}  >
          {city.name}
    </li>
              ))
             )
          }



   
       
          </ul>
        </div>
        }
       

        </div>
        <div className='w-full  sm:w-[50%] h-full py-3 xl:py-0  relative   '>
         <input type='text' value={filterValue} placeholder='Search by doctor,hospital,specialist,disease,pharmcy' className='w-full h-full px-2  sm:px-8  outline-none text-[15px] '  onChange={handlechangepateint}        onClick={()=>handledropdownsec(2)}/>
         {
                show === 2 &&
          <div className={`w-full absolute  h-[300px] top-14 sm:top-16 bg-white cursor-pointer z-50  categorieslist `}>
          <ul >
          {

loading ? (
  <div className='w-full flex justify-center items-center'>
        <img src={Loadingimage} alt='img' className='w-16 h-16'/>
      </div>
):(
  filteredDvms.map(Dvmser=>(
    <li className='border-b-2 py-2 px-3 main-color ' key={Dvmser._id}  onClick={()=>handleSelectCategory(Dvmser)}>
    {Dvmser.name}
    </li>
  ))
)
}
        
           
          </ul>
          </div>
        

         }


       

         
        </div> 
        <div className='w-full sm:w-[15%]   '>
        <button onClick={handleshowdoctor}  className='w-full bg-[#4C63BD] text-white flex justify-center items-center sm:h-14 py-2 sm:py-0 rounded-bl rounded-br sm:rounded-bl-none   sm:rounded-tr sm:rounded-br text-lg'>
        Search
         </button>
        </div>
         
        </div>
      </from>
       
      </div>
          
       </div>
  )
}

export default Banner
