import React from 'react'
import Navbar from '../../components/global/navbar'
import Footer from '../../components/global/footer'
import Logo from '../../assests/Images/globalpics/Layer 1.svg'
import Appimage from '../../assests/Images/png/member-area-img.png'
import Headroom from 'react-headroom'
import { FaCheck } from "react-icons/fa6";
const Member = () => {
  return (
     <>
      <div className='bg-[#F8F9FA]'>
      <Headroom>
      <div className='navshadow bg-white z-50'>
       <Navbar/>
       </div>
       </Headroom>
        <div className='w-full'>
           <div className='w-11/12  mx-auto py-5 md:py-10  '>
           <div className='flex flex-col items-center space-y-2 py-3 '>
            <div>
          <img src={Logo} alt='img'/>
            </div>
            <div className=' w-full sm:w-11/12 md:w-3/5 xl:w-2/5  '>
            <p className='text-[15px] text-center text-black text-opacity-85'>The User Dashboard, along with lots of other useful features, is now available on the     Callvet App .</p>
            </div>

           </div>
       <div className=' w-full md:w-11/12 lg:w-4/5  xl:w-3/5   mx-auto flex flex-col space-x-0 sm:flex-row md:space-x-2'>
         <div className='w-full sm:w-2/4   '>
         <div className='  w-11/12 mx-auto  sm:ml-auto'>
         <img src={Appimage} alt='img' />

         </div>
         </div>
         <div className='w-full sm:w-2/4 '>
             <ul className='flex flex-col space-y-4 py-3 text-[15px] text-black text-opacity-85'>
                <li className='flex items-center space-x-2' ><span className='text-[--primary]'><FaCheck/></span><span>Keep track of all your appointments</span></li>
                <li className='flex items-center space-x-2'><span className='text-[--primary]'><FaCheck/></span><span>Easily cancel or reschedule appointments</span></li>
                <li className='flex items-center space-x-2'><span className='text-[--primary]'><FaCheck/></span><span>Add family members</span></li>
                <li className='flex items-center space-x-2'><span className='text-[--primary]'><FaCheck/></span><span>Review and rate doctors</span></li>
                <li className='flex items-center space-x-2 sm:space-x-0  md:space-x-2'><span className='text-[--primary]'><FaCheck/></span><span>Add your medical records </span><span className='text-[--primary] border border-[--primary] px-1 rounded-xl'>Coming soon</span></li>
             </ul>
             <div className='flex flex-col space-y-3'>
                <label className='text-[15px] text-black text-opacity-85'>Get the free Callvet App!</label>
                <div className='flex flex-row w-full border border-[--primary] rounded-md '>
                <input type='text' placeholder='' value={'03196219448'} className='w-2/3 py-2 px-2 text-[#686868] outline-none rounded-tl-md rounded-bl-md'/>
                 <button className='bg-[--primary] w-2/6 text-[15px] text-white py-2 rounded-tr-md rounded-br-md'>Send App Link</button>
                </div>

             </div>
         </div>
       </div>
           </div>
        </div>
      </div>
    
        <Footer/>
     </>
  )
}
export default Member
