import React, { useContext, useEffect, useState} from 'react'
import  Logo from '../../../assests/Images/globalpics/Layer 1.svg'
import './navbar.css'
import Responsivenavbar from './responsivenavbar';
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import {Link, useNavigate } from 'react-router-dom';
import { PatientContext } from '../../../context/pateintcontext';
import { useDispatch,useSelector } from 'react-redux';
import {fetchapiservices} from '../../../redux/actions/servicesaction';
import {fetchapicities} from '../../../redux/actions/citylistaction'
const Navbar = () => {
  const dispatch=useDispatch();
  const Navigate=useNavigate();
  const {ServiesDvm}=useSelector((state)=>state.services);
  const {CitySelect}=useSelector((state)=>state.city);
  const{selectedPatient,setSelectedPatient}=useContext(PatientContext);
  const[membershow,setMembershow]=useState(false);
  const [show,setShow]=useState(false);
  const [listshow,setListshow]=useState(null);


  const  handleclickcategory=(city,category)=>{
    Navigate(`/pages/doctor/${city}/${category}`)
  }

  const handletoshow=(index)=>{
    setListshow(index);
  }
   const handleshow=()=>{
      setShow(!show)
   }
   const handleuser=()=>{
    setMembershow(!membershow);
   }
   const logoutuser=()=>{
    setMembershow(false)
    setSelectedPatient(null);
   }
   useEffect(()=>{
   dispatch(fetchapiservices())
   },[dispatch]);
   useEffect(()=>{
    dispatch(fetchapicities())
   },[dispatch])



useEffect(()=>{

  const handleClickOutside=(event)=>{
    if(!event.target.closest('.dropdown-container')){
      setMembershow(false);
    }
    if(!event.target.closest('.dropdownref')){
      setShow(false)
    }
  };
  document.addEventListener('click',handleClickOutside);
return ()=>{
  document.removeEventListener('click',handleClickOutside);
};

},[membershow,show]);
 


  return (
       <>
      
     <div>
       
       <Responsivenavbar/>
    <div className='w-full  hidden  lg:block  ' >
           <div className='w-11/12 mx-auto'>
               <div className='flex flex-row items-center justify-between py-3 '>
                     <div  className='w-36 h-auto' >
                     <Link to='/'>
                       <img src={Logo}  alt='img' className='w-full h-full'/>
                     </Link>
                     </div>
              
                 <div className='flex flex-row lg:space-x-1 xl:space-x-3'>
                 <div className='dropdownref' >
                        <ul className='flex space-x-4 text-black text-[15px] font-semibold cursor-pointer relative ' >
                        <li className='main-color flex items-center space-x-1  py-2 px-3' onClick={handleshow} >
                         <span>Doctors</span> 
                         <span><IoIosArrowDown/></span>
                          </li>
                          {
                            show &&
                            <div  className='absolute bg-white w-56 h-auto top-10 rounded text-black py-1  px-1 box-shadow   z-50' >
                            <p className=' py-2 text-primary text-base font-semibold'>Find doctor by speciality</p>
                             <ul className='  flex flex-col space-y-2 font-normal'>
                              {
                                ServiesDvm.map((categories,index)=>(
                                  <li key={categories._id}>
                                  <div className='main-color px-1 py-2 flex items-center space-x-3' onClick={() => handletoshow(index)}>
              <span>{categories.name}</span> <span><IoIosArrowDown /></span>
            </div>
                {
                  listshow===index &&(
                    <div className='text-[13px] text-[#686868]'>
                        {CitySelect.map((city)=>(
                          <div key={city._id} className='px-1 py-2' onClick={()=>handleclickcategory(city.name, categories._id)} >
                    {categories.name} in {city.name}
                  </div>
                        ))}
                    </div>
                  )
                  
                }
                                  </li>
                                ))

                              }
                              
                             </ul>
                           </div>
                          }
                          <li className='main-color  py-2 px-3'>Health Blog</li>
                          <Link to='/joinasdoctor'><li className='main-color  py-2 px-3'>Join as Doctor</li></Link>
                          <li className='main-color   py-2 px-3'>Help</li>
                        </ul>
                       </div>
                       <div >
                       <ul  className='flex items-center space-x-4 '  >
                  <li className='relative dropdown-container'>
                  {selectedPatient ? (
  <p className='flex space-x-2 items-center text-[--primary] text-15 font-semibold cursor-pointer' onClick={handleuser}  > 
    <span>{selectedPatient}</span> 
    <span><IoIosArrowDown/></span> 
  </p>
) : (
  <button className='bg-[--primary] text-white w-[156px] h-[40px] text-15 rounded cursor-pointer'>Login / Signup</button>
)}
{
  membershow &&(
    <div className='w-[140px] left-[-30px] h-14 top-9   bg-white  absolute border rounded-md cursor-pointer'>
<ul className='text-sm px-5 py-1 cursor-pointer'>
 <Link to='/pages/member/'><li className=''>Member Area</li></Link> 
  <li onClick={logoutuser} >Log Out</li>
</ul>
</div>
  )

}


</li>
                    
      
                       <li>
                             <button className='flex flex-row justify-center items-center  text-white space-x-3 w-[156px] h-[40px] bg-[#28B446] text-[15px] rounded	'>
                              <span><FaPhoneAlt/></span>
                              <span>04238900939</span>
                             </button>
                          </li>
                          </ul>
                       </div>

                 </div>



                      
               </div>
           </div>
       </div>
       </div>
       





       </>
      
  )
}
export default Navbar
