  import {setAppointmentData, setLoading, setError} from '../reducers/bookappoinmentreducer';
  import axios from 'axios';
export const bookAppointment = ({ date, doctorId, hospitalAvailableId, hospitalfee, 
  hospitalname,homeclinic}) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.post('https://callvet.raabtastore.com/api/doctor/specificHospitalAvailability', {
      date,
      doctorId,
      hospitalAvailableId,
    });
    dispatch(setAppointmentData({
      ...response.data.data,
      hospitalfee, 
      hospitalname,
      doctorId,
      hospitalAvailableId,
      homeclinic
     
    }));
  } catch (error) {
    dispatch(setError(error.response.data));
  } finally {
    dispatch(setLoading(false));
  }
};









