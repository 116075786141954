import React from 'react'
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Doctorlist = ({ username, yearofexperince, pictue, doctorId, doctordetails, hospital }) => {
    const navigate = useNavigate();
  
    const handleViewProfile = (profileid) => {
        navigate(`/userdetail/userone/${profileid}`);
    };

    const getAvailabilityMessage = (schedule) => {
      const today = new Date().getDay();
      const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      const checkDay = (dayIndex) => {
          const day = daysOfWeek[dayIndex];
          return schedule[day]?.status ? day.charAt(0).toUpperCase() + day.slice(1) : null;
      };
      const todayStatus = checkDay(today);
      const tomorrowStatus = checkDay((today + 1) % 7);
      if (todayStatus) {
          return `Available today`;
      } else if (tomorrowStatus) {
          return `Available tomorrow`;
      } else {
          for (let i = 2; i < 7; i++) {
              const futureStatus = checkDay((today + i) % 7);
              if (futureStatus) {
                  const futureDate = new Date();
                  futureDate.setDate(futureDate.getDate() + i);
                  return `Available on ${futureDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`;
              }
          }
          return "No availability this week";
      }
  };
    return (
        <>
            <div className='w-full flex flex-col space-y-10 px-4 md:px-0 py-7 bg-[#F8F9FA]'>
                <div className='flex flex-col w-full md:w-5/6 mx-auto bg-white px-2 sm:px-10 py-10 shadow-md rounded-md relative'>
                    {/* <div className='absolute right-0 bg-[#C9D0EB] text-[10px] top-0 rounded px-2 py-1'>
                        Features doctor
                    </div> */}
                    <div className='flex flex-row justify-between w-full lg:w-auto'>
                        <div className='flex flex-row space-x-2 sm:space-x-7'>
                            <img src={`https://callvet.raabtastore.com/${pictue}`} alt='img' className='w-20 h-20 sm:w-[100px] sm:h-[100px] mt-3 rounded-full' />
                            <div className='flex flex-col space-y-1 ml-5'>
                                <NavLink to='/userdetail/userone/'>
                                    <div className='text-lg sm:text-xl font-semibold text-black pt-2 leading-6 sm:leading-9'>
                                        {username}
                                    </div>
                                </NavLink>
                                <div className='text-sm'>
                                    {doctordetails}
                                </div>
                                <div className='flex flex-col space-y-2 sm:flex-row sm:space-y-0 py-2 sm:mt-4'>
                                    <div className='flex flex-col border-r-0 sm:border-r-[1px] pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Wait Time</div>
                                        <div className='text-sm font-semibold text-black'>Under 15 Min</div>
                                    </div>
                                    <div className='flex flex-col border-r-0 sm:border-r-[1px] pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Experience</div>
                                        <div className='text-sm font-semibold text-black'>{yearofexperince}</div>
                                    </div>
                                    <div className='flex flex-col pl-3 pr-3'>
                                        <div className='text-xs text-[#686868] font-normal'>Satisfied Patients</div>
                                        <div className='text-sm font-semibold text-black'>99% (228)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Buttons for large screens */}
                        <div className='hidden lg:block'>
                            <div className='flex flex-col space-y-3 mt-3'>
                                <button className='w-56 h-12 border border-[--primary] text-black text-sm rounded hover:bg-[--primary] hover:text-white transition-all duration-3000' onClick={() => handleViewProfile(doctorId)}>
                                    View Profile
                                </button>
                                <NavLink to='/pages/appoinetment/'>
                                    <button className='text-center bg-[--primary] text-white w-56 h-12 rounded text-sm box-shadow'>
                                        Book Appointment
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    {/* Note a paragraph */}
                    <div className='py-4 lg:py-1'>
                        <p className='text-xs'>He is a very good doctor. The Staff was very Cooperative and the clinic was neat and clean</p>
                    </div>
                    {/* Appointment section */}
                    <div className='flex flex-row space-x-3 w-full cursor-pointer my-2'>
                        {
                            hospital.map((hospitaldetail) => (
                                <div className='flex flex-col border rounded-md w-2/6 h-auto space-y-2 py-2' key={hospitaldetail._id}>
                                    <div className='text-sm font-semibold px-4 pt-2'>{hospitaldetail.selectHospital.name}</div>
                                    <div className='flex flex-col px-4'>
                                     <p className='text-sm text-[--primary]'>{hospitaldetail.onlineAvailable ? 'Online available':''}</p>
                                     <p className='text-sm text-[--primary]'>{hospitaldetail.homeCheckUp ? 'HomeCheck is Available':''}</p>
                                    </div>
                                    <div className='flex flex-row justify-between px-4'>
                                        <span className='text-[#28B446] text-sm'>
                                            {getAvailabilityMessage(hospitaldetail)}
                                        </span>
                                        <span className='text-black text-opacity-90 text-[13px] font-normal'>Rs{hospitaldetail.fee}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/* Buttons for small screens */}
                    <div className='block lg:hidden mt-16'>
                        <div className='flex flex-col space-y-3 sm:space-y-0 sm:flex-row space-x-0 sm:space-x-2'>
                            <NavLink to='/userdetail/userone/'>
                                <button className='space-x-1 w-full sm:w-56 h-11 border border-[--primary] text-[15px] rounded p-3 hover:bg-[--primary] hover:text-white transition-all duration-3000'>
                                    View Profile
                                </button>
                            </NavLink>
                            <NavLink to='/pages/appoinetment/'>
                                <button className='text-center text-white bg-[--primary] text-[15px] w-full sm:w-56 h-11 rounded p-3 hover:bg-opacity-85 transition-all duration-3000 box-shadow'>
                                    Book Appointment
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Doctorlist;
