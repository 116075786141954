import React from 'react'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Headingdoctor = ({doctorcity,doctorName,doctorcategorey}) => {
  if (!doctorcity || !doctorcity.length) {
    return <div>No city found</div>;
  }

  const citydoctor=doctorcity[0];
  return (
     <>
      <div className='w-full  '>
                 <ul className=' text-[10px] sm:text-xs flex space-x-0 sm:space-x-2 cursor-pointer uppercase'>
                    <li className='flex items-center space-x-1'><span className='text-primary'>Home</span><span className='text-[#D4D7D9]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center space-x-1'><span className='text-primary'>Pakistan</span> <span className='text-[#D4D7D9]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center space-x-1'><span className='text-primary'>{citydoctor.city}</span><span className='text-[#D4D7D9]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center '>  {doctorcategorey && <span>{doctorcategorey[0].name}</span>}<span className='text-[#D4D7D9]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center text-[#46484b]  text-opacity-70'><span>{doctorName.username}</span></li>
                 </ul>
               </div>
     </>
  )
}

export default Headingdoctor
