import React, { useState, useContext } from 'react'
import AppoinetmentFor from './left'
import Confrimbooking from './right'
import { useLocation,useNavigate } from 'react-router-dom'
import { PatientContext } from '../../../context/pateintcontext'
import Addpateintpop from './addpateintpopup'

const Mainappoinetmentdetail = () => {
  const {setSelectedPatient } = useContext(PatientContext);
  const [showpopup,setShowpopup]=useState(false);
  const [pateinname,setPateintName]=useState('')
  const [addpateintname,setAddpateintname]=useState();
  const [pateinterror ,setPateinterror]=useState(false);
  const [appointmenterror,setAppointmenterror]=useState(false);
  const [paymenterror,setPaymenterror]=useState(false)
   const[appoinetmentselection,setAppoinetmentselection]=useState('')
   const [payment,setPayment]=useState('');
   const navigate =useNavigate()
  const location =useLocation();
  const pateintnumber=location.state ? location.state.Pateintnumber:'';
  const dayofbooking=location.state ? location.state.day:'';
  const monthofbooking=location.state ? location.state.mon :'';
  const timeofbooking=location.state ? location.state.time :'';
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!pateinname || pateinname.trim() === '') {
      setPateinterror('Please enter the Name');
    }else{
      setPateinterror('')
    }
    if (!appoinetmentselection || appoinetmentselection === null) {
      setAppointmenterror('Please select an Appointment');
    }else{
       setAppointmenterror('')
    }
    if (!payment || payment === null) {
      setPaymenterror('Please select a Payment method');
    }
    else{
      setPaymenterror('');
    }
    if (pateinname && pateinname.trim() !== '' && appoinetmentselection  && payment) {
      setSelectedPatient(pateinname);
      if (payment === 1) {
        navigate('/pages/pays/payonline/', {
          state: {
            months: monthofbooking,
            days: dayofbooking,
            times: timeofbooking,

          }
        });
      } else if (payment === 2) {
        navigate('/pages/pays/paycash/', {
          state: {
            pateintConfirm: pateinname,
            months: monthofbooking,
            days: dayofbooking,
            times: timeofbooking,
          }
        });
      }
    }
  };
  
  




  return (
     <>

     {
      showpopup &&
      <Addpateintpop showpopup={showpopup} setShowpopup={setShowpopup} phonenumber={pateintnumber}  setAddpateintname={setAddpateintname} />
      
     }
        <div className='w-full pb-0 lg:pb-10 mt-3'>
          <div >
          <form onSubmit={handlesubmit}  
          className='flex  flex-col space-y-3 lg:space-y-0 lg:flex-row w-full lg:w-11/12 mx-auto  md:my-7 space-x-0 lg:space-x-3 '>
           <div className='w-full  sm:w-11/12  md:w-full mx-auto md:mx-0 lg:w-[70%] '>
            <AppoinetmentFor phonenumber={pateintnumber}  setShowpopup={setShowpopup} pateinname={pateinname} setPateintName={setPateintName} setAppoinetmentselection={setAppoinetmentselection}  setPayment={setPayment}
              addpateintname={addpateintname} pateinterror={pateinterror} appointmenterror={appointmenterror}
              paymenterror={paymenterror}
            />
           </div>  
           <div className='w-full sticky bottom-0 sm:static  mx-auto lg:mx-0    lg:w-[30%]  '>
            <Confrimbooking day={dayofbooking} month={monthofbooking} time={timeofbooking} />
           </div>  


           </form>
           
          </div>

        </div>
     </>
  )
}
export default Mainappoinetmentdetail
