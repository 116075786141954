import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { FaCalendar } from "react-icons/fa";
import { IoMdCash } from "react-icons/io";
import { FaMoneyBills } from "react-icons/fa6";
import { FaLaptop } from "react-icons/fa";
import { MdOutlineStarRate } from "react-icons/md";







const Doctorslider = () => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
          slidesToSlide: 0
        },
        tablet: {
          breakpoint: { max: 1024, min: 767 },
          items: 4,
          slidesToSlide: 1 
        },
        mobile: {
          breakpoint: { max: 767, min: 500 },
          items: 3,
          slidesToSlide: 1 
        },
        smallscreen:{
          breakpoint: { max: 500, min: 0 },
          items: 2,
          slidesToSlide: 1 
        }
      };
      const CustomLeftArrow = ({ onClick}) => {
        return (
          <button onClick={onClick} className=' absolute top-4 sm:top-5 md:top-4 lg:top-5 left-0 z-30  ' >
            <IoIosArrowBack className='text-black font-bold text-2xl' />
          </button>
        );
      };
    
      const CustomRightArrow = ({ onClick }) => {
        return (
          <button onClick={onClick} className='absolute top-4 sm:top-5 md:top-4 lg:top-5 right-0 z-30 '  >
            <IoIosArrowForward className='text-black font-bold text-2xl' />
          </button>
        );
      };
  return (
    <>
    <div className='w-full'>
    <div className='w-11/12 mx-auto'>
    <Carousel 
                axis='horizontal'
            showStatus={false}
            responsive={responsive}
            customLeftArrow={ <CustomLeftArrow  />}
            customRightArrow={ <CustomRightArrow  />}
               className='  w-full h-16 md:h-14  lg:h-16 cursor-pointer   '>
             <div className='text-black flex justify-center items-center  font-medium border border-[--primary] w-[145px] sm:w-[170px] md:w-[150px] lg:w-[180px] h-[40px] mx-3      rounded text-xs sm:text-sm  visited:bg-[rgb(212,212,255)] '   >   Doctors  Near Me </div>
             <div className='text-black flex justify-center items-center font-medium border border-[--primary] w-[145px] sm:w-[170px] md:w-[150px] lg:w-[180px] h-[40px]  mx-3  rounded text-xs sm:text-sm   visited:bg-[rgb(212,212,255)] ' >   Available Today</div>
              <div  className='text-black flex justify-center items-center font-medium border border-[--primary] w-[145px] sm:w-[170px] md:w-[150px] lg:w-[180px] h-[40px]  mx-3  rounded text-xs sm:text-sm  visited:bg-[rgb(212,212,255)] ' >  Doctors Blew Rs.1000</div>
             <div className='text-black flex justify-center items-center font-medium border border-[--primary] w-[145px] sm:w-[170px] md:w-[150px] lg:w-[180px] h-[40px]  mx-3  rounded text-xs sm:text-sm   visited:bg-[rgb(212,212,255)] '  > Lowest Fee</div>
             <div className='text-black flex justify-center items-center font-medium border border-[--primary] w-[145px] sm:w-[170px] md:w-[150px] lg:w-[180px] h-[40px]  mx-3  rounded text-xs sm:text-sm   visited:bg-[rgb(212,212,255)] ' >Online Now  </div>
              <div className='text-black flex justify-center items-center font-medium border border-[--primary] w-[145px] sm:w-[170px] md:w-[150px] lg:w-[180px] h-[40px]  mx-3  rounded text-xs sm:text-sm   visited:bg-[rgb(212,212,255)] '  > Highest Rated</div>
               </Carousel>
    </div>
    </div>
 
    </>
  )
}

export default Doctorslider
