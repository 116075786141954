import React from 'react'
import Security from '../../../assests/Images/png/security.png'

const Statisfied = () => {
  return (
    <>
     <div className='w-11/12 md:w-4/5 xl:w-3/5  mx-auto  mb-5 px-3 py-5'>
       <div className='flex flex-row items-center space-x-4'>
       <div>
       <img src={Security} alt='img' className='w-[120px] h-[50px] sm:h-14 sm:w-14'/>
       </div>
       <div className='flex flex-col space-y-3 '>
          <h2 className='text-lg text-[#232426] leading-6'>95% patients feel satisfied after booking appointment from oladoc</h2>
          <p className='text-[#686868] text-[13px] font-light'>It takes only 30 sec to book an appointment</p>
       </div>

       </div>
     </div>
    </>
  )
}

export default Statisfied
