import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaPhoneAlt } from "react-icons/fa";


const Stickybar = () => {
  return (
     <>
        <div className='bg-white w-full sticky bottom-0 z-50 block md:hidden'>
        <div className='flex flex-row space-x-2 py-2 px-1'>
         <button className='w-1/2 bg-[#28B446] text-white py-2 rounded-md flex flex-row space-x-1 justify-center items-center'>
         <span><FaPhoneAlt/></span>   <span>Call Helpline</span>
         </button>
         <button className='w-1/2 bg-[--primary] text-white py-2 rounded-md'>
         <NavLink to='/pages/appoinetment/'> Book Appoinetment</NavLink>
         </button>
         
        </div>
        </div>
     </>
  )
}

export default Stickybar
