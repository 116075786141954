import React from 'react'
import Navbar from '../../components/global/navbar'
import Footer from '../../components/global/footer'
import Docdownload from '../../components/pages/joinasdoctor/docdownload'
import  Forourdoctor from '../../components/pages/joinasdoctor/forourdoctor'
import Headroom from 'react-headroom'
const Joindoctor = () => {
  return (
      <>
      <div className='bg-[#F8F9FA]'>
      <Headroom>
    <div className='navshadow  z-50 bg-white '>
 <Navbar />
 </div>
    </Headroom>
      <Docdownload/>
      <Forourdoctor/>
      <Footer/>
      </div>
    
        
      </>
  )
}

export default Joindoctor
