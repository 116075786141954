import { createSlice } from "@reduxjs/toolkit";
const AppointmentReducer = createSlice({
    name: 'appointment',
    initialState: {
      slots: [],
      online: false,
      selectedDay: null,
      user: null,
      message: '',
      status: '   ',
      error: null,
      username: '', 
      image:'',
      hospitalfee: '',
      hospitalname:'',
      doctorId:'',
      hospitalAvailableId:'',
      loading:false,
      homeclinic :''



    },
    reducers: {
      setAppointmentData(state, action) {
        const { slots, online, selectedDay, user, message, doctor,hospitalfee,hospitalname,doctorId,hospitalAvailableId,homeclinic } = action.payload;
        state.slots = slots;
        state.online = online;
        state.selectedDay = selectedDay;
        state.user = user;
        state.message = message;
        state.username = doctor.username;
        state.image=doctor.image;
        state.hospitalfee = hospitalfee;
        state.hospitalname=hospitalname;
        state.doctorId=doctorId;
        state.hospitalAvailableId=hospitalAvailableId;
        state.homeclinic=homeclinic;
      },
      setLoading(state, action) {
        state.status = action.payload ? 'loading' : ' ';
        state.loading=action.payload;

      },
      setError(state, action) {
        state.status = 'failed';
        state.error = action.payload;
      },
    },
  });
  
  export const { setAppointmentData, setLoading, setError } = AppointmentReducer.actions;
  export default AppointmentReducer.reducer;