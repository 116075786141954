import React from 'react'
const Headeronline = () => {
  return (
    <>
    <div className='w-full h-10 bg-[#4C63BD] flex items-center '>
    <div className='w-11/12  mx-auto flex justify-end items-center'>
   <p className='text-white text-[13px]'>Need help? Call us @ <span className='text-[#FFFF7F]'>04238900939</span></p>
    </div>
    </div>
    </>
  )
}

export default Headeronline
