import React from 'react'
import Navbar from '../../components/global/navbar'
import Banner from '../../components/global/banner'
import Booking from '../../components/pages/home/booking'
import Lookingfor from '../../components/pages/home/lookingfor'
import Letgetsyou from '../../components/pages/home/letgetsyou'
import Providers from '../../components/pages/home/providers'
import Paractices from '../../components/pages/home/paractices'
import Finddoctorcity from '../../components/pages/home/finddoctorcity'
import Footer from '../../components/global/footer'
import './home.css'
const Home = () => {
  return (
    <>
    <div className='secondary-background' >
    <Navbar/>
     <Banner/>
    </div>
     <Booking/>
     <Lookingfor/>
     <div className="w-full  h-auto pt-28 relative bg-[#F7F9FC]">
     <div class="custom-shape-divider-top-1715061159">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z" class="shape-fill"></path>
    </svg>
</div>
 <Letgetsyou/>
     <Providers/>     
    </div>
     <Paractices/>
     <Finddoctorcity/>
     <Footer/>
    
    </>
     
  )
}

export default Home
