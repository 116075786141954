import { createSlice } from "@reduxjs/toolkit";
const DoctorlistReducer=createSlice({
   name:'Doctorlist',
   initialState:{
     viewdoctor:[],
     loading:false,
   },
   reducers:{
    setDoctorList(state,action){
    state.viewdoctor=action.payload;
    },
    setLoading(state,action){
    state.loading=action.payload;
    },
   }
})
export const{setDoctorList,setLoading}=DoctorlistReducer.actions;
export default DoctorlistReducer.reducer;