import  {createSlice} from "@reduxjs/toolkit";
const  ViewProfileReducer=createSlice({
  name:'Viewprofile',
  initialState:{
    ViewPro:[],
    loading:false,
    error:null,
  },
  reducers:{
    setViewProfile(state,action){
     state.ViewPro=action.payload;
    },
    setLoading(state,action){
        state.loading=action.payload;
    },
    setError(state,action){
        state.error=action.payload;
    }
  }
})
export const {setViewProfile,setLoading,setError}=ViewProfileReducer.actions;
export default ViewProfileReducer.reducer;
