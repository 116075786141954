import React from 'react'

const Speclization = ({doctorservices}) => {
  return (
      <>
        <div className='flex flex-col space-y-5 px-2 py-4  box  rounded-lg lg:shadow-none lg:rounded-none mt-5 lg:border-b-2 cursor-pointer bg-white  '>
           <div className='flex flex-col space-y-4 px-1'>
             <div className='   text-[17px] font-semibold text-black   leading-10 '>
             Specialization
             </div>
             <div className=' w-full'>
         <ul className='flex flex-row  flex-wrap text-sm text-black px-5'>
         {
          doctorservices && doctorservices.map((service)=>(
            <li className='w-3/6 list-disc	 h-7' key={service._id}>
          {service.name}
         </li>
          ))
         }
       
         </ul>
        
         </div>
           </div>
        </div>
      </>
  )
}

export default Speclization
