import {setCategorycity,setLoading,setError} from '../reducers/categorycityreducer';
import axios from 'axios';
export const fetchapicatergorycity=(city,category)=>async(dispatch)=>{
try{
    dispatch(setLoading(true));
    const response=await axios.get(`https://callvet.raabtastore.com/api/doctor/getAllDoctor?city=${city}&category=${category}`)
     dispatch(setCategorycity(response.data.data.docs))
}
catch(error){
    console.log('Error are showing',error);
    dispatch(setError(true)); 
}
finally{
    dispatch(setLoading(false))
}

}