import React from 'react'


const Reviews = () => {
  return (
    <>
    {/* <div className='w-full p-5  lg:pt-5  md:pb-10 box  rounded-lg lg:shadow-none lg:rounded-none mt-7 lg:border-b-2 bg-white '>
        <div className='flex flex-col px-1  space-y-4'>
           <div className='text-lg sm:text-xl font-medium text-black leading-7  sm:leading-10'>
              <div><span>Assist. Prof. Dr. M. Fahad Saleem Reviews</span> <span className='text-[#686868] text-opacity-90'>(2145)</span> </div>
           </div>
           <div className='flex flex-col space-y-3 lg:space-y-0 lg:flex-row   '>
           <div className='flex flex-row  items-center w-full lg:w-2/4  '>
           <div className='  w-[50px] h-[45px] sm:w-[50px] sm:h-[50px]  md:h-[60px] md:w-[60px]  bg-[#232426] rounded-full sm:rounded-full text-xs sm:text-base flex items-center justify-center text-white'>
             <div>98%</div>
           </div>

           <div className='text-black px-2 sm:px-5  lg:px-2 flex items-center'>
                <p>Satisfied out of 567 patients</p>
           </div>
           </div>


            <div className='flex flex-row w-full lg:w-2/4   items-center justify-start lg:justify-end xl:justify-start space-x-1 sm:space-x-3  xl:space-x-5 '>
            <div className='text-sm font-normal px-0   text-black  text-opacity-80 '>
            <ul  className='flex flex-col text-xs sm:text-sm space-y-4 '>
                <li>Doctor Checkup</li>
                <li>Clinic Environment</li>
                <li>Staff Behaviour</li>
            </ul>
           </div>

           <div className='flex flex-col space-y-3'>
             <div className='flex flex-row items-center space-x-2 '>
              <div className='w-32 bg-[#000000] bg-opacity-25 rounded-lg  h-1'>
                 <div className='w-[120px] bg-[#232426] rounded-lg  h-1'>
                 </div>
              </div>
              <div className='text-black  '>
                96%
              </div>
             </div>

             <div className='flex flex-row items-center space-x-2 '>
              <div className='w-32 bg-[#000000] bg-opacity-25 rounded-lg  h-1'>
                 <div className='w-[120px] bg-[#232426] rounded-lg  h-1'>
                 </div>
              </div>
              <div className='text-black  '>
                95%
              </div>
             </div>

             
             <div className='flex flex-row items-center space-x-2 '>
              <div className='w-32 bg-[#000000] bg-opacity-25 rounded-lg  h-1'>
                 <div className='w-[120px] bg-[#232426] rounded-lg h-1'>
                 </div>
              </div>
              <div className='text-black  '>
                98%
              </div>
             </div>
           </div>
            </div>

          

           </div>


           <div className=' flex flex-col space-y-2 border rounded-md px-5  py-4 '>
              <p className='text-sm text-black '> Dr. Afshan Ahmad is fantastic!! Always quick, listens, and I refer her without a second thought! </p>
              <p className='text-[13px] text-[#686868]  flex flex-row space-x-3 '>
              <span>Verified patient:</span>
              <span>S** ***d</span> 
              <span> 1 year ago</span></p>
           </div>   
           <div>
            <button className='border-[1px] border-black text-[15px] w-56 h-14 rounded-md mt-7  '>Read all reviews</button>
</div>


        </div>
    </div> */}
      
    </>
  )
}

export default Reviews
