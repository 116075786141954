import React from 'react'
import Image from '../../../../assests/Images/Svg/Book Appointment with Dr. Samina Kausar at Iqraa Medical Complex (Ext.) in Johar Town, Lahore _ oladoc.com_files/1560423926_samina-kausar.webp'
import { FaRegClock } from "react-icons/fa";


const  Confrimbooking = ({day,month,time}) => {
  return (
      <>
      <div className='w-full rounded  box py-4 px-4 bg-white ' >
      <div className='flex flex-row space-x-2'>
      <div className='w-[20%] hidden md:block '>
    <img src={Image} alt='img' className='w-14 h-14 rounded-full' />
      </div>
      <div className='flex flex-col space-y-1 w-[80%] '>
      <div className='block md:hidden'>
      <div  className=' flex flex-row text-lg items-center space-x-2 '>
     <img src={Image} alt='img' className='w-14 h-14 rounded-full' />
     <div>Dr. Samina Kausar</div>
     </div>
      </div>
      <h2 className='text-lg hidden md:block'>Dr. Samina Kausar</h2>
     

     <p className='text-sm text-[#46484B] leading-6 hidden md:block'>Dermatologist, Laser Specialist, Cosmetologist
Iqraa Medical Complex (Ext.) (Johar Town, Lahore) <b>Rs. 2,500</b></p>
<p className='flex items-center justify-center py-1 rounded-lg text-[#46484B]   space-x-1 text-[15px] bg-[#F4F7FF] w-3/5 '>
<span><FaRegClock/></span> <span>{month}</span> <span> {day}</span><span>{time}</span>
</p>
      </div>
      </div>

      <div className='my-3'>
      <button type='submit' className='rounded py-2 text-center text-base bg-[--primary] text-white w-full cursor-pointer'>
        Confrim booking
      </button>
      </div>

      </div>
      </>
  )
}

export default  Confrimbooking
