import React from 'react'
import Test from '../../../assests/Images/Svg/Group 36788.svg'
import Pets from '../../../assests/Images/Svg/Group 36789.svg'
import Birds from '../../../assests/Images/Svg/Path 2763.svg'
import Groming from '../../../assests/Images/Svg/Group 36790.svg'
import vacine from '../../../assests/Images/Svg/Group 36791.svg'
import cat from '../../../assests/Images/homepics/animal-vaccination.svg'



const Lookingfor = () => {
//   const [showaftertime,setShowAfterTime]=useState(false);
//   const handleclick=()=>{
//     setShowAfterTime(true)
    
//   }

    
    
  

  return (
    <>
        <div className='w-full  '>
        <div className='w-11/12 mx-auto py-5'>
        <div className='text-start leading-[39px] text-2xl sm:text-[26px] py-5   font-semibold'>
        I’m Looking For
         </div>

        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-3   pt-4 pb-4'>
          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={Pets} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Animals Test
             </div>

          </div>
          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={Test} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Pets Surgery
             </div>

          </div>

          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={cat} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Pets Vaccination
             </div>

          </div>


          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={Birds} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Birds Checkup
             </div>

          </div>


          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={Groming} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Pets Grooming
             </div>

          </div>


          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={vacine} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Domestic Animals
             </div>

          </div>


          <div className='flex flex-col justify-center items-center  h-[181px] w-40  space-y-4  rounded-[10px]  hover:shadow-lg cursor-pointer bg-[#D2D8EF66] py-2' >
             <div className='w-28 h-28 bg-[#F6F7FC] rounded-full flex items-center justify-center  my-2 box-shadow   '>
             <img src={vacine} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm  text-black'>
             Pets Checkup
             </div>

          </div>



        </div> 
       {/* <div className='flex justify-center my-2'>
       <button className={`text-white primary-background rounded px-3 py-1 ${showaftertime ? 'hidden':'block'}` } onClick={handleclick}>LoadMore</button>
       </div> */}


{/* {
  showaftertime &&
  <div className='grid grid-cols-6 gap-4 pt-4 pb-4'>
       <div className='flex flex-col justify-center items-center h-52  space-y-4  rounded border border-[#4C63BD] hover:shadow-lg bg-[#EAEFFF80]' >
             <div className='w-24 h-24 bg-white rounded-full flex items-center justify-center mt-6 '>
             <img src={Checkup} alt='img' className='h-16 w-16'/>
             </div>
             <div className='text-sm'>
             Domestic Animals
             </div>

          </div>
       </div>

} */}
      

       
        </div>
        </div>
    </>
  )
}

export default Lookingfor
