import React from 'react'
const Servicesdoc = ({doctorservices}) => {
  return (
    <>
        <div className='w-full p-5  lg:pt-5  md:pb-10 box  rounded-lg lg:shadow-none lg:rounded-none mt-7 lg:border-b-2  bg-white'>
      <div className='flex flex-col space-y-4 px-1 '>
        <div className='flex flex-row justify-between items-center '>
       <div className=' text-[17px] font-semibold text-black   leading-10'>Services</div> 
       <div className='text-[#686868] text-sm cursor-pointer'>(view all)</div>
        </div>
        <div className=' w-full '>
        <div  className='flex flex-col space-y-2 sm:space-y-3 md:space-y-0 md:flex-row md:flex-wrap     text-sm ' >
          {
            doctorservices && doctorservices.map((service)=>(
              <div key={service._id}>
              {service.name}

              </div>
            ))
          }
      
              </div>
              <div>
     
    </div>
           
        </div>

      </div>
    </div>
    

     
 

    {/* <div className='w-full p-5  lg:pt-5  md:pb-10 box  rounded-lg lg:shadow-none lg:rounded-none mt-7 lg:border-b-2  bg-white  '>
      <div className='flex flex-col space-y-4 px-1 '>
        <div className='flex flex-row justify-between items-center '>
       <div className=' text-[17px] font-semibold text-black   leading-10'>Conditions Treated</div> 
       <div className='text-[#686868] text-sm cursor-pointer'>(view all)</div>
        </div>
        <div className=' w-full '>
            <div className='flex  flex-col space-y-3 md:space-y-0 md:flex-row   md:flex-wrap text-sm ' >
                <div className='md:w-3/6 md:h-7 lg:h-12   '>ALS (اے ایل ایس)   </div>
                <div className='md:w-3/6 md:h-7 lg:h-12 '>Autism (آٹزم) </div>
                <div className='md:w-3/6 md:h-7 lg:h-12  '>Dementia (بھولنے کی بیماری)  </div>
                <div className='md:w-3/6 md:h-7  lg:h-12  '> Dizziness (چکر آنا) </div>
                <div className='md:w-3/6 md:h-7 lg:h-12 '>Epilepsy (مرگی) </div>
             

            </div>

        </div>

      </div>
    </div> */}
      
    </>
  )
}

export default Servicesdoc
