import React, { useState } from 'react'
import Image1 from '../../../../../assests/Images/Svg/visa-mastercard.png'
import Image2 from '../../../../../assests/Images/Svg/jazz-cash-tab-logo.png'
import Image3 from '../../../../../assests/Images/Svg/easy-paisa-icon.png'
import Image4 from '../../../../../assests/Images/Svg/hbl-logo.png'
import Image5 from '../../../../../assests/Images/Svg/bank-transfer.png'
import { IoIosArrowDown } from "react-icons/io";
import './paycomp.css'

const Optionpayment = ({ active, setActive }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleclikactive = (index) => {
    setSelectedOption(index);
    setActive(index);
  };


  return (
     <>
             <div className='w-full rounded-md box py-4 my-7 px-6 bg-white'>
                <div className='text-lg font-medium'>Choose a payment method</div> 
            <div className='flex flex-col space-y-3 my-4 cursor-pointer'>
            <div className={`flex flex-row items-center justify-between  px-3 h-14 rounded-md  ${selectedOption===1 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#F4F7FF]':'border  text-[#46484b] text-opacity-60 bg-white'}`} onClick={()=>handleclikactive(1)}>
                  <div className='flex items-center space-x-3 '>
                    <img src={Image1} alt='img' />
                    <p className='text-sm text-[#46484b] '>Pay via Credit / Debit (ATM) Card</p>
                  </div>
                   <input type='checkbox' className='rounded-box' checked={selectedOption===1} onChange={()=>{}} />
                </div> 

                <div className={`flex flex-row items-center justify-between  px-3 h-14 rounded-md  ${selectedOption===2 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#F4F7FF]':'border  text-[#46484b] text-opacity-60 bg-white'}`} onClick={()=>handleclikactive(2)}>
                  <div className='flex items-center  space-x-3'>
                    <img src={Image2} alt='img' />
                    <p className='text-sm text-[#46484b] '>JazzCash Mobile Wallet</p>
                  </div>
                   <input type='checkbox'  className='rounded-box' checked={selectedOption===2} onChange={()=>{}} />
                </div>  

                <div className={`flex flex-row items-center justify-between  px-3 h-14 rounded-md  ${selectedOption===3 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#F4F7FF]':'border  text-[#46484b] text-opacity-60 bg-white'}`} onClick={()=>handleclikactive(3)}>
                  <div className='flex items-center space-x-3 '>
                    <img src={Image3} alt='img'  className='w-9'/>
                    <p className='text-sm text-[#46484b] '>Easypaisa Mobile Wallet </p>
                  </div>
                   
                   <input type='checkbox'  className='rounded-box' checked={selectedOption===3} onChange={()=>{}} />
                </div>  

                <div className={`flex flex-row items-center justify-between  px-3 h-14 rounded-md  ${selectedOption===4 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#F4F7FF]':'border  text-[#46484b] text-opacity-60 bg-white'}`} onClick={()=>handleclikactive(4)}>
                  <div className='flex items-center  space-x-3'>
                    <img src={Image4} alt='img' className='w-9 '/>
                    <p className='text-sm text-[#46484b] '> 
                        HBL Direct Transfer                   
                     </p>
                  </div>
                   <input type='checkbox'  className='rounded-box' checked={selectedOption===4} onChange={()=>{}} />
                </div> 

                <div className={`flex flex-row justify-between items-center  px-3 py-3 rounded-md  ${selectedOption===5 ? 'border border-[#98abf7] text-[#46484b] text-opacity-100 bg-[#F4F7FF]':'border  text-[#46484b] text-opacity-60 bg-white'}`} onClick={()=>handleclikactive(5)}>
                  <div className='flex items-center  space-x-3'>
                    <img src={Image5} alt='img' className='w-9 '/>
                    <p className='text-sm text-[#46484b] '> Bank Transfer (from any bank)</p>
                  </div>
                    <div><IoIosArrowDown/></div>
                </div> 

                <div className={`w-full bg-white transition-max-height duration-500 px-3   overflow-hidden `} style={{ maxHeight:  selectedOption===5 ?'1000px':'0'}}
                >
                <div className='text-sm text-[#46484b] py-1'>Instructions:</div>
                <ul className='px-3 text-sm text-[#46484b] flex flex-col space-y-1'>
                  <li className='list-decimal	' >Transfer Rs. 2500 to the Habib bank account mentioned below (Wallet won't be applied)</li>
                  <li className='list-decimal	' >Take a screenshot of the completed transaction</li>
                  <li className='list-decimal	' >WhatsApp the screenshot to: 03000647873</li>
                </ul>
                <div className='bg-[#F4F7FF] w-full px-3 my-4 py-3'>
                <h1 className='text-[#46484b] text-opacity-70 text-[15px] font-semibold py-3 '>ACCOUNT DETAILS</h1>
                <p className='text-xs text-[#46484b] text-opacity-70'>Account number</p>
                <h1 className='text-[#232426]'>10607901038503 <span className='text-sm text-[#46484b] text-opacity-70'>(branch code:1060)</span></h1>
                <p className='text-xs text-[#46484b] text-opacity-70'>Account title</p>
                <h1 className='text-[#232426]'>OLADOC <span className='text-sm text-[#46484b] text-opacity-70'>(branch code:1060)</span></h1>
                <p className='text-xs text-[#46484b] text-opacity-70'>Bank</p>
                <h1 className='text-[#232426]'>Habib Bank Ltd <span className='text-sm text-[#46484b] text-opacity-70'>(branch: Garden Town)</span></h1>
                <p className='text-xs text-[#46484b] text-opacity-70'>IBAN</p>
                <h1 className='text-[#232426]'>PK68 HABB 0010607901038503 </h1>

                </div>

                </div>
            </div>  

            <div className='text-center text-[#46484b] text-xs'>Changed your mind about paying online?</div>  
              </div>

     </>
  )
}

export default Optionpayment
