import { createSlice } from "@reduxjs/toolkit";
const ServicesReducer=createSlice({
  name:'Serviceslist',
  initialState:{
     ServiesDvm:[],
     loading:false,
  },
  reducers:{
    setServicesList(state,action){
        state.ServiesDvm=action.payload;
    },
    setLoading(state,action){
        state.loading=action.payload;
    },

  }

})

export const{setServicesList,setLoading}=ServicesReducer.actions;
export default ServicesReducer.reducer;