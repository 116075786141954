import React from 'react'
import { useState } from 'react'
import './joindoctor.css'
import Mobiledemo from '../../../assests/Images/Svg/Group 36787.svg'

const Forourdoctor = () => {
    const [tabshow,setTabshow]=useState(1)
    const showtabs=(index)=>{
        setTabshow(index);
    }
  return (
     <>
        <div className='w-full bg-white  '>
          <div className='w-11/12  mx-auto pt-7 pb-3'>
             <p className='text-center text-[--primary] text-base lg:text-sm '>Join as a Doctor</p>
             <h1 className='text-center text-3xl sm:text-4xl  font-bold uppercase pt-5'>Steps to Log In as a Doctor</h1>
             <div className='py-2 sm:py-10 '>
        <ul className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2  xl:grid-cols-none  xl:flex xl:flex-row justify-center xl:space-x-5 text-center text-xs sm:text-sm lg:text-lg text-black text-opacity-85 cursor-pointer  font-semibold h-auto  xl:h-10 '>
            <li onClick={()=>showtabs(1)} className={`py-1   sm:py-2 ${tabshow===1 ?'activediv active ':''}`}>Registration</li>
            <li onClick={()=>showtabs(2)} className={`py-1 sm:py-2  ${tabshow===2 ?'activediv active  ':''}`}>Profile Setup</li>
            <li onClick={()=>showtabs(3)} className={`py-1 sm:py-2  ${tabshow===3 ?'activediv active ':''}`}>Identity Verification</li>
            <li onClick={()=>showtabs(4)} className={`py-1 sm:py-2   ${tabshow===4 ?'activediv active ':''}`}>Connect with Patients</li>
        </ul>

        {
            tabshow===1 &&
            <div className=' w-full sm:mt-3 '>
            <div className='flex flex-col md:flex-row justify-center py-7'>
            <div className=' w-full md:w-2/4    '>
              <div className='bg-[--primary] w-full lg:w-[85%] h-72 text-white  rounded-md  box-shadow flex justify-center mt-2 md:mt-10 '>
              <div className=' flex flex-col space-y-3 pt-16 w-4/5'>
               <h1 className='text-3xl font-bold '>Registration</h1>
               <p className='text-sm leading-6 text-justify'>Download the Callvet app and sign up by providing your name, email ID, mobile number, and the name of your clinic or establishment.</p>
               </div>
              </div>
            </div>
            <div className='w-full md:w-2/4   '>
            <div className='w-11/12 sm:w-3/5 md:w-2/4 mx-auto'>
            <img src={Mobiledemo} alt='img' className='w-full h-full'/>
            </div>

            </div>

            </div>
        </div>
        }
        {
            tabshow===2 &&
            <div className=' w-full sm:mt-3 '>
            <div className='flex flex-col md:flex-row  justify-center py-7'>
            <div className='w-full md:w-2/4  '>
            <div className='bg-[--primary] w-full lg:w-[85%] h-72 text-white  rounded-md  box-shadow flex justify-center mt-2 md:mt-10'>
              <div className=' flex flex-col space-y-3 pt-16 w-4/5'>
               <h1 className='text-3xl font-bold '>Profile Setup</h1>
               <p className='text-sm leading-6 text-justify'>
               Enter your profile details, including your medical credentials, fees, operating hours, and additional practice information.</p>
               </div>
              </div>
            </div>
            <div className='w-full md:w-2/4   '>
            <div className='w-11/12 sm:w-3/5 md:w-2/4 mx-auto'>
            <img src={Mobiledemo} alt='img' className='w-full h-full'/>
            </div>


            </div>

            </div>
        </div>
       
        }
        
        {
            tabshow===3 &&
            <div className=' w-full md:mt-3 '>
            <div className='flex  flex-col md:flex-row  justify-center py-7'>
            <div className='w-full md:w-2/4  '>
            <div className='bg-[--primary] w-full lg:w-[85%] h-72 text-white  rounded-md  box-shadow flex justify-center mt-2 sm:mt-10 '>
              <div className=' flex flex-col space-y-3 pt-16 w-4/5'>
               <h1 className='text-3xl font-bold '>Identity Verification</h1>
               <p className='text-sm leading-6 text-justify'>Verify your details by completing a quick online process to activate your account and go live.</p>
               </div>
              </div>
            </div>
            <div className='w-full md:w-2/4   '>
            <div className='w-11/12 sm:w-3/5 md:w-2/4 mx-auto'>
            <img src={Mobiledemo} alt='img' className='w-full h-full'/>
            </div>
            </div>

            </div>
        </div>
        }
        
        {
            tabshow===4 &&
            <div className=' w-full md:mt-3 '>
            <div className='flex flex-col md:flex-row justify-center py-7'>
            <div className='w-full md:w-2/4  '>
            <div className='bg-[--primary] w-full lg:w-[85%] h-72 text-white  rounded-md  box-shadow flex justify-center mt-2 sm:mt-10 '>
              <div className=' flex flex-col space-y-3 pt-16 w-4/5'>
               <h1 className='text-3xl font-bold '>Connect with Patients</h1>
               <p className='text-sm leading-6 text-justify'>
               Easily connect with patients using our platform. Schedule appointments, share updates, and provide care efficiently to keep patients satisfied.</p>
               </div>
              </div>
            </div>
            <div className='w-full md:w-2/4  '>
            <div className='w-11/12 sm:w-3/5 md:w-2/4 mx-auto'>
            <img src={Mobiledemo} alt='img' className='w-full h-full'/>
            </div>           
             </div>

            </div>
        </div>
        }
        
        

       

        </div>
          </div>
       
        </div>
     </>
  )
}

export default Forourdoctor
