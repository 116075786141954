import { createSlice } from "@reduxjs/toolkit";
const FinddoctorReducer=createSlice({
name:'Finddoctor',
initialState:{
    Finddoc:[],
    loading:false,
},
reducers:{
    setFindDoctor(state,action){
        state.Finddoc=action.payload;
    },
    setLoading(state,action){
     state.loading=action.payload;
    }
}
})

export const {setFindDoctor,setLoading}=FinddoctorReducer.actions;
export default FinddoctorReducer.reducer;