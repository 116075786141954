import React from 'react'
import { CiUser } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import { MdOutlinePayment } from "react-icons/md";
import Image from '../../../../../assests/Images/doctorlistsvg/image 120.svg'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoLocation } from "react-icons/io5";








const Pateintdetail = ({NameofConfirm,dayofConfirm,monthofConfirm,timeofConfirm}) => {
  return (
       <>
        <div className='w-full box rounded-md  py-7 mt-7 bg-white  '>
          <div className='flex flex-col space-y-7 '>
          <div className=' flex flex-row items-center justify-between border-b px-4 py-2 '>
            <div className='flex flex-row items-center space-x-3'>
              <img src={Image} alt='img' className='w-[90px] h-[90px]'/>
            <div>
            <h2 className='text-lg leading-9'>Assist. Prof. Dr. M. Fahad Saleem</h2>
            <p className='text-sm text-[#686868]'>M.B.B.S .F.C.P.S (Pets Surgery)</p>
          </div>
          </div>
          <div className='text-xl cursor-pointer'>
            <HiOutlineDotsVertical/>
          </div>
          </div>
          
           <div className='flex flex-row justify-between  w-4/5'>
           <div className='flex flex-row px-3 space-x-3'>
          <div className='text-3xl text-[#686868] text-opacity-60'>
          <CiUser/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'> Patient Name</p> 
        <h1 className='text-base font-normal'>{NameofConfirm}</h1>
          </div>

          </div>


          <div className='flex flex-row px-3 space-x-3'>
          <div className='text-3xl text-[#686868] text-opacity-60 '>
          <MdOutlinePayment/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'> Fee</p> 
        <h1 className='text-base font-normal leading-8'>1500</h1>
        <p className='text-[--primary] text-[10px]'>Pay online  & Get 400 OFF</p>
          </div>

          </div>
        

           </div>
        

          <div className='flex flex-row px-3 space-x-3 w-4/5'>
          <div className='text-3xl text-[#686868] text-opacity-60'>
          <CiCalendar/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'> Appointment Time</p> 
        <h1 className='text-base font-normal'><span>{dayofConfirm}</span><span>{monthofConfirm} 2024,</span> <span>{timeofConfirm}</span></h1>
          </div>

          </div>

          <div className='flex flex-row px-3 space-x-3 w-4/5'>
          <div className='text-3xl text-[#46484b] text-opacity-60'>
          <IoLocation/>
          </div>
          <div className='text-black'>
        <p  className='text-sm text-opacity-60 leading-8'>location</p> 
        <h1 className='text-base font-normal'>Dr. Asif Alam Khan at Ali Medical Centre (Islamabad)

</h1>
          </div>

          </div>


       
          </div>

        </div>
       </>
  )
}

export default Pateintdetail
