import React from 'react'
import Navbar from '../../../global/navbar'
// import Cofirmsms from './paycashcomp/cofirmsms'
import Pateintdetail from './paycashcomp/pateintdetail'
import Importantnote from './paycashcomp/importantnote'
import Footer from '../../../global/footer'
import Downloadapp from './paycashcomp/downloadapp'
// import Completeprofile from './paycashcomp/completeprofile'
import { useLocation } from 'react-router-dom'


const  Paycash = () => {
  const location =useLocation();  
  const NameofConfirm = location.state ? location.state.pateintConfirm : '';
  const dayofConfirm = location.state ? location.state.days : '';
  const monthofConfirm = location.state ? location.state.months : '';
  const timeofConfirm = location.state ? location.state.times : '';
  return (
    <>
    <div className='bg-[#F8F9FA]'>
    <div className='navshadow bg-white z-50'>
    <Navbar/>
    </div>
    <div className='w-full  '>
    <div className='w-11/12 mx-auto '>
     <h2 className='text-xl pt-6 font-medium'>Appointment Details</h2>
    <div className='w-full lg:w-4/5 xl:w-1/2 mx-auto my-3'>
       {/* <Cofirmsms/> */}
       <Pateintdetail 
       NameofConfirm={NameofConfirm}
        dayofConfirm={dayofConfirm} 
        monthofConfirm={ monthofConfirm}
        timeofConfirm={timeofConfirm}

          />
       <Importantnote/>
       <Downloadapp/>
       {/* <Completeprofile/> */}

       </div>

    </div>
      

    </div>
      
    <Footer/>
    </div>
  
    </>
  )
}

export default  Paycash
