import React from 'react'
const Stickyconfirm = ({showingtime}) => {
  return (
     <>
        <div className='w-full sticky bottom-0 z-30 py-2 px-1  bg-white block md:hidden'>
              <p>{showingtime}</p>
          <button className='bg-[--primary] text-white w-full py-2 rounded-md'>Confrim booking</button>
        </div>
     </>
  )
}

export default Stickyconfirm
