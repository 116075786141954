import React from 'react'
import Home from './pages/home'
import Doctor from './pages/doctor';
import {BrowserRouter as Router , Routes , Route} from 'react-router-dom';
import Userone from './components/userdetail/userone';
import Appoinetment from './pages/appoinetment/index';
import Appoinetmentdetail from './pages/appoinementdetail';
import Payonline from './components/pages/pays/payonline';
import Paycash from './components/pages/pays/paycash';
import Member from './pages/member';
import Joindoctor from './pages/joinasdoctor';
import Testing from './testing';
import Error from './error'
const App = () => {
  return (
    <Router>
        <Routes>
        <Route path='/' element={<Home/>} errorElement={<Error/>}/>
        <Route path='/pages/doctor/:city/:category' element={<Doctor/>} errorElement={<Error/>}/>
        <Route path='/userdetail/userone/:id' element={<Userone/>} errorElement={<Error/>} />
        <Route path='/pages/appoinetment/' element={<Appoinetment/>} errorElement={<Error/>}/>
        <Route path='/pages/appointmentdetail/' element={<Appoinetmentdetail/>} />
        <Route path='/pages/pays/payonline/' element={<Payonline/>} errorElement={<Error/>}/>
        <Route path='/pages/pays/paycash/' element={<Paycash/>}  errorElement={<Error/>}  />
        <Route path='/testing/' element={<Testing/>}   errorElement={<Error/>} />
        <Route path='/pages/member/' element={<Member/>}   errorElement={<Error/>}/>
        <Route path='/joinasdoctor' element={<Joindoctor/>} errorElement={<Error/>}/>
        <Route path="*" element={<Error />} />
        </Routes>
    </Router>
  )
}

export default App

