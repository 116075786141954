import React from 'react'

const Error = () => {
  return (
     <>
        <div className='text-center text-2xl pt-8 bg-black text-white'>
              Page is not found
        </div>
     </>
  )
}

export default Error
