import React from 'react'
// import { CiDiscount1 } from "react-icons/ci";

const Doctorname = ({username,image,  fee,hospitalselected,homeclinic}) => {
  return (
       <>
        <div className=' w-11/12  md:w-4/5 xl:w-3/5   mt-7 mb-8 mx-auto bg-white rounded-md shadow-md px-5 py-8'>
        <div className='flex flex-col'>
           <div className='flex flex-row items-center  space-x-4 border-b-[1px] pb-3'>
             <div className=''>
              <img src={`https://callvet.raabtastore.com/${image}`} alt='img' className='h-[100px] w-[100px] md:w-[80px] md:h-[80px] xl:w-[60px] xl:h-[60px] rounded-full'/>
             </div>
             <div className='flex flex-col space-y-2 '>
              { username && <h2 className='text-lg sm:text-[22px] text-black leading-7'>{username}</h2> }  
              { hospitalselected && <p className='text-sm text-[#686868] '>{hospitalselected}</p> }
                    <p className='text-[--primary] text-sm'>{homeclinic ? 'Home clinic':' Not aviable for home clinic  '}</p>
                { fee && <p className='text-sm text-text-[#686868] font-semibold'>Fee:Rs.{fee}</p> }
             
             </div>
           </div>
           {/* <div className='flex justify-center items-center'>
           <div className='flex items-center text-[13px] space-x-1 text-[#4C63BD] pt-3'>
              <span><CiDiscount1 /></span>
              <span>Pay Online & Get Up to 40% off</span>
           </div>
           </div> */}

        </div>
        </div>
       </>
  )
}

export default Doctorname
