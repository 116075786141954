import { setVarifyLogin,setSuccess,setError} from '../reducers/verfiyloginreducer';
import axios from 'axios';
export const  verifyLogin = (PhoneNumber, otp) => async (dispatch) => {
try {
  const response = await axios.post('https://callvet.raabtastore.com/api/user/client-login-verify', {
    phone:PhoneNumber, otp
  });
    if(response.data.success){
      const token=response.data.data.token;
      dispatch(setVarifyLogin(PhoneNumber, otp,token));
      dispatch(setSuccess(true));
      localStorage.setItem('authtoken',token);
    }
    else{
      dispatch(setError(response.data.data.message));
      dispatch(setSuccess(false));
    }
}
catch (error) {
    dispatch(setError('Internal server error'));
    dispatch(setSuccess(false));
} 
};
