import React from 'react'
import { FaRegCircleCheck } from "react-icons/fa6";


const Discountcode = () => {
  return (
      <>
        <div className='w-full rounded-md box py-4 bg-white'>
        <div className='flex flex-col space-y-3'>
          <div className='flex flex-row items-center px-6 space-x-3'>
           <div className='text-3xl text-[#E5220C]'><FaRegCircleCheck/></div>
           <p className='text-primary text-[15px] '>Your appointment has been booked. Please make payment to complete the process.</p>
          </div>

          <div className='flex flex-row items-center justify-between px-6 '>
          <h3 className='text-[#232426] text-[15px]'>Have a coupon/promo code?</h3>
          <div className='flex flex-row w-2/5  '>
             <input type='text' placeholder='Enter discount code here' className='border-[1px] border-r-0 outline-none w-4/5 px-2 text-sm py-2 rounded-tl-lg rounded-bl-lg'/>
             <button className='bg-[#4C63BD] w-1/5 py-2 rounded-tr-lg rounded-br-lg text-white text-sm' >Apply</button>
          </div>
          </div>

        </div>

        </div>
      </>
  )
}

export default Discountcode
