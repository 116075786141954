import React from 'react'

const Downloadapp = () => {
  return (
      <>
     <div className='w-full rounded-md box py-4 mt-7  bg-white '>
     <div className='flex flex-col space-y-3 '>
     <div className=' border-b px-4 py-2'>
            <p>Download the Callvet App</p>
          </div>
     <div className='flex flex-row text-sm px-4 text-[#46484b]'>
       <p>Add your medical records and share with Dr. Asif Alam Khan to let him know about your medical history.</p>
     </div>




     </div> 

     
     <div className='flex justify-center w-full my-3 '>
         <button className='bg-[--primary] text-center
          text-white px-3 py-2 rounded-md text-[13px]'> Download App</button>
     </div>        
    </div>
      </>
  )
}

export default Downloadapp
