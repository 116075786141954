import React from 'react'

const Aboutreviws = ({doctornamerated}) => {
  return (
      <>
       <div className='flex flex-col space-y-5 px-2 py-4  box  rounded-lg lg:shadow-none lg:rounded-none mt-5 lg:border-b-2 cursor-pointer bg-white '>
       <div className='flex flex-col space-y-4 px-1  '>
       <div className='text-lg sm:text-xl font-medium    leading-10'>
       Reviews About {doctornamerated.username} (0)
       </div>
       <div className=' flex flex-col space-y-2 border rounded-md px-5  py-4 '>
        
              {/* <p className='text-sm text-black  '> Dr. Afshan Ahmad is fantastic!! Always quick, listens, and I refer her without a second thought! </p>
              <p className='text-xs text-[#686868]  flex flex-row space-x-3 '>
              <span>Verified patient:</span>
              <span>S** ***d</span> 
              <span> 1 year ago</span></p> */}
           </div>  


           <div className=' flex flex-col space-y-2 border rounded-md px-5  py-4 '>
           
              {/* <p className='text-sm text-black '> Dr. Afshan Ahmad is fantastic!! Always quick, listens, and I refer her without a second thought! </p>
              <p className='text-xs text-[#686868]  flex flex-row space-x-3 '>
              <span>Verified patient:</span>
              <span>S** ***d</span> 
              <span> 1 year ago</span></p> */}
           </div>  



             
           <div className='my-5 px-1'>
            <button className='border-[1px] border-black text-[15px] w-56 h-14 rounded-md'>Load more reviews</button>
           </div>

       </div>
       </div>

      </>
  )
}

export default Aboutreviws
