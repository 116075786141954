import React from 'react'

const Finddoctorcity = () => {
  return (
    <>
      <div className='w-full  pb-20  ' >
        <div className='w-11/12 py-3   mx-auto '>
        <div className='text-start my-7 text-2xl sm:text-[26px] font-semibold   '>
        Were Available in these Cities       
          </div>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-4 py-2 cursor-pointer  text-base md:text-lg font-semibold'>
          <div className='  border-b-[1px] border-black py-3'>Multan</div>
          <div className='  border-b-[1px] border-black py-3'>Karachi</div>
          <div className='  border-b-[1px] border-black py-3'>Islamabad</div>
          <div className='  border-b-[1px] border-black py-3'> Lahore</div>
        </div>
      

        </div>
      </div>
    </>
  )
}

export default Finddoctorcity
