import React from 'react'
import Image from '../../../../../assests/Images/doctorlistsvg/image 120.svg'
import { FaRegClock } from "react-icons/fa";
 import { MdOutlinePayment } from "react-icons/md";
 import { RiWhatsappFill } from "react-icons/ri";


const Payknow = ({active,  appointmentDay, 
  appointmentMonth, 
  appointmentTime }) => {
let icon =null;
switch(active){
  case 1:
  case 2:
  case 3:
  case 4:
  icon=<MdOutlinePayment/>;
  break;
  case 5:
    icon=<RiWhatsappFill/>;
    break;
    default:
      icon=null;

}




  return (
      <>
      <div className='w-full rounded-md box py-2 px-3 bg-white'>
      <div className='flex flex-row space-x-2'>
      <div className='w-[20%] '>
    <img src={Image} alt='img' className='w-14 h-14 rounded-full' />
      </div>
      <div className='flex flex-col space-y-3 w-[80%] '>
     <h1 className='text-lg'>Dr. Samina Kausar</h1>
     <p className='text-sm text-[#46484B] leading-6'>qraa Medical Complex (Ext.)</p>
<p className='flex items-center justify-center py-1 rounded-lg text-[#46484B]   space-x-1 text-[15px] bg-[#F4F7FF] w-3/5 '>
<span><FaRegClock/></span> 
<span>{appointmentMonth}</span>
            <span>{appointmentDay}</span>
            <span>{appointmentTime}</span>
</p>
 <p className='text-start text-2xl'>Rs. 2,500</p>
      </div>
      </div>

      <div className='my-3'>
      <button
  className={`rounded py-2 flex justify-center space-x-2 items-center text-base 
  ${
    active === 1 ? 'bg-[#4C63BD]' :
    active === 2 ? 'bg-[#4C63BD]' :
    active === 3 ? 'bg-[#4C63BD]' :
    active === 4 ? 'bg-[#4C63BD]' :
    active === 5 ? 'bg-[#455A64]' : 'bg-[#E6220C]'
  } text-white w-full cursor-pointer`}
>
 {icon}
  <span>{active === 5 ? 'Pay now' : 'Pay now'}</span>
</button>
      </div>
      </div>

      </>
  )
}

export default Payknow
