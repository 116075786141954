import { configureStore } from "@reduxjs/toolkit";
import ServicesReducer from '../reducers/servicesreducer';
import CityReducer from '../reducers/citylistreducer';
import Doctorlistreducer from "../reducers/doctorlistreducer";
import Viewprofilereducer from "../reducers/viewprofilereducer";
import FinddoctorReducer from '../reducers/finddoctorspeclityreducer';
import CategorycityReducer from '../reducers/categorycityreducer';
import AppointmentReducer from '../reducers/bookappoinmentreducer';
import LoginotpaReducer from '../reducers/loginotpreducer';
import verfiyloginreducer from "../reducers/verfiyloginreducer";

const store=configureStore({
   reducer:{
       services:ServicesReducer,
       city:CityReducer,
       doctor:Doctorlistreducer,
       view:Viewprofilereducer,
       finddoc:FinddoctorReducer,
       citycategory:CategorycityReducer,
       appointment:AppointmentReducer,
       otplogin:LoginotpaReducer,
       userverfied:verfiyloginreducer
   },

})
export default store;