import React from 'react'
const Profiledoc = ({doctorDetails}) => {
  return (
      <>
          <div className='w-full flex justify-center items-center'>
      </div>

          <div className='w-full p-5  lg:pt-5  md:pb-10 box  rounded-lg lg:shadow-none lg:rounded-none mt-7 lg:border-b-2 bg-white   '>
        <div className='flex flex-row   px-1 space-x-3 sm:space-x-8    '>
      <div className=''>
        <img src={`https://callvet.raabtastore.com/${doctorDetails.image}`} alt='img' className=' h-[100px] w-[100px] rounded-full mt-1'/>
      </div>
      <div className='flex flex-col space-y-2 md:space-y-1 xl:space-y-2  '>
      <div className='text-lg sm:text-xl md:text-2xl font-normal text-[#232426] pt-2 leading-6 sm:leading-10'>
        {doctorDetails.username}
        </div>
        <div className='text-sm leading-6 text-black' >
              {doctorDetails.bio}  
        </div>
        <div className='flex flex-row space-x-1 sm:space-x-3  mt-4'>
        <div className='flex flex-col border-r-0 sm:border-r-[1px]  sm:pr-2'>
            <div className='text-[10px] sm:text-xs  text-[#686868]  text-opacity-80'>Wait Time</div>
            <div className=' text-xs  sm:text-sm font-medium text-black'>Under 15 Min</div>
          </div>
          <div className='flex flex-col border-r-0 sm:border-r-[1px] sm:pr-2'>
            <div className='text-[10px]  sm:text-xs  text-[#686868]  text-opacity-80'>5 Years</div>
            <div className='text-xs sm:text-sm font-medium text-black'>Experience</div>
          </div>
          <div className='flex flex-col '>
            <div className='text-[10px]  sm:text-xs  text-[#686868]  '>100% (177)</div>
            <div className='text-xs sm:text-sm font-medium text-black'>Satisfied Patients</div>
          </div>
        </div>
      </div>
    </div>   
        </div>
      
      </>
  )
}

export default Profiledoc
