import React,{useState} from 'react'
import { FaHospitalAlt } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { FaClock } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { FaHeadphones } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { Link } from 'react-router-dom';
const Bookingconfirm = () => {
    const [time,setTime]=useState(false);

    const showthetimes =()=>{
        setTime(!time);
      
    }
  return (
      <>

        <div className='flex flex-col space-y-5 px-2 py-4  box  rounded-lg lg:shadow-none lg:rounded-none mt-5 lg:border-b-2 cursor-pointer bg-white '>
           <div className='flex flex-row  items-center space-x-2 px-1     '>
              <div className='text-[--primary]'>
              <FaHospitalAlt/>
              </div>
              <div className='text-xl sm:text-[22px] font-medium leading-6'>
              City Hospital 
(Multan) 
              </div>
           </div>
           <div className=' flex flex-row justify-between px-2 py-1 border-b-[1px] text-sm '>
             <div className='text-black text-opacity-60'>Fee:</div>
             <div className=' text-black'>Rs. 2,500</div>
           </div>
           <div className='flex flex-row justify-between px-2 py-1 border-b-[1px] text-sm '>
           <div className='text-black text-opacity-60'>Address:</div>
             <div className=' flex flex-row  font-medium items-center space-x-1 '>
             <span><IoLocation/></span> 
             <span className='underline'>Main PIA Housing Society Road</span>
             </div> 
           </div>


           <div className='flex flex-col  space-y-3' onClick={showthetimes}>
           <div className='flex flex-row justify-between px-2 py-1  text-sm'>
                <div className='flex flex-row items-center space-x-1 text-[#28B446]'>
                <span><FaClock/></span>
                <span> Available tomorrow  </span> 
                </div>
                <div className='flex flex-row items-center space-x-1 text-black' >
                   <span>  07:00 PM - 08:30 PM  </span>
                   <span><IoIosArrowDown/></span>
                </div>
             </div>


              { 
            <div className='flex flex-col w-full space-y-2 transition-max-height duration-500  overflow-hidden px-2 text-black text-sm ' style={{ maxHeight:   time ?'1000px':'0'}}>
               <div className='flex flex-row justify-between '>
                <div>Monday</div>
                <div>07:00 PM - 08:30 PM</div>
               </div>

               <div className='flex flex-row justify-between '>
                <div>Tuesday</div>
                <div>07:00 PM - 08:30 PM</div>
               </div>

               <div className='flex flex-row justify-between '>
                <div>Friday</div>
                <div>07:00 PM - 08:30 PM</div>
               </div>

               <div className='flex flex-row justify-between '>
                <div>Saturday</div>
                <div>07:00 PM - 08:30 PM</div>
               </div>


               

            </div>
        

           } 
           </div>

          


            
            
            <button className='w-full mx-auto rounded py-2 text-center text-base bg-[--primary] text-white'>
            <Link to='/pages/appoinetment/'>   Book Appointment</Link>
                </button>
                

        </div>
    
      </>
  )
}

export default Bookingconfirm
