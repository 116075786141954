import React from 'react'
import  Image from '../../../assests/Images/Svg/callvethomepage.svg'
const Docdownload = () => {
  return (
       <>
        <div className='w-full bg-[#F8F9FA]   '>
          <div className='w-full sm:w-11/12 mx-auto  '>
            <div className='flex flex-col md:flex-row sm:justify-between space-x-0 xl:space-x-5 w-full py-4 md:py-0'>
              <div className=' w-full md:w-2/4 flex flex-col  justify-center space-y-5 space-x-0  px-3 sm:px-0 '>
              <h1 className='text-4xl md:text-3xl lg:text-4xl  xl:text-5xl font-bold leading-[50px] '>Make your practice perfect.</h1>
              <div className='w-full lg:w-4/6'>
              <p className='text-[#686868] text-sm leading-6 text-start md:text-justify  border-0  md:border-l-[1px] border-[--primary] px-0 md:px-3 lg:px-5'>
              Join our platform as a doctor to reach more patients, with online appointment bookings and efficient practice management. Sign up today!
              </p>

              </div>
              <div className='flex flex-row w-3/4 space-x-3 '>
                <button className='w-2/4 bg-[--primary] text-white text-sm py-3 rounded '>Download</button>
                <button className='w-2/4 border border-[--primary] text-sm py-3 rounded text-[--primary] navshadow hover:bg-[--primary] hover:text-white'>Learn More</button>
               </div>
              </div>
              <div className='w-full md:w-2/4  flex justify-start sm:justify-center '>
              <div className='w-4/5 mx-auto sm:mx-0 sm:w-2/4 md:w-2/4 '>
              <div className='mt-11 '>
              <img src={Image} alt='img' className='w-full h-full'/>

              </div>
              </div>
              </div>

             
            </div>
          </div>
        </div>
       </>
  )
}

export default Docdownload
