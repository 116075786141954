import React, { useState,useEffect } from 'react'
import Image1 from '../../../assests/Images/Svg/Vector (1).svg'
import Image2 from '../../../assests/Images/Svg/Evening.svg'
import Image3 from '../../../assests/Images/Svg/moon.svg'
import Login from './otp/login'
import Loadingimage from '../../../assests/Images/globalpics/loadingimg.svg'
import './booking.css'
const Whichtime = ({daybooking,slots,loading,monthbooking}) => {
  const [selectedTime, setSelectedTime] = useState({ category: null, index: null });
  const [showLogin, setShowLogin]=useState(false);
  const [showcolored,setShowcolored]=useState(true)
  const  handleTimeClick=(category,index)=>{
    setSelectedTime({category, index});
    setShowcolored(false);
    setShowLogin(true);
  }
  const handleLoginClose = () => {
    setSelectedTime({ category: null, index: null });
    setShowLogin(false);
  };
  const morningSlots = ['07:00', '07:20', '07:40', '08:00', '08:20', '08:40', '09:00', '09:20', '09:40', '10:00', '10:20', '10:40', '11:00', '11:20', '11:40'];
  const  afternoonSlots= ['12:00', '12:20', '12:40', '13:00', '13:20', '13:40', '14:00', '14:20', '14:40'];
  const   eveningSlots = ['15:00', '15:20', '15:40', '16:00', '16:20', '16:40', '17:00', '17:20', '17:40', '18:00', '18:20', '18:40', '19:00', '19:20', '19:40'];
      const categorizedSlots = {
        morning: slots.filter(slot => morningSlots.includes(slot)),
        evening: slots.filter(slot => afternoonSlots.includes(slot)),
        afternoon: slots.filter(slot => eveningSlots.includes(slot)),
    };
    if(loading){
      return(
        <div className='w-full flex justify-center items-center py-36'>
        <img src={Loadingimage} alt='img' className='w-16 h-16 '/>
      </div>
      )
    }
    if (slots.length === 0) {
      return <p className='text-center text-[--primary] py-44'>No free slots available for selected date</p>;
    }
    const getSelectedTime=()=>{
      if(!selectedTime.category || selectedTime.index === null) return ' ';
      const categorySlots = categorizedSlots[selectedTime.category];
      return categorySlots[selectedTime.index];
    }
   return(
      <> 

{showLogin && (
        <div className='relative'>
          <Login time={getSelectedTime()} daybooking={daybooking}     monthbooking={monthbooking} onClose={handleLoginClose} />
        </div>
      )}  
            <div className='w-full'>
        <div className='flex flex-col space-y-3'>
          <div className='flex flex-col '>
            <div className='flex flex-row space-x-2 items-center py-3'>
              <span>
                <img src={Image1} alt='img' className='text-xl'/>
              </span>
              <span className='font-semibold text-[#232426]'>Morning Slots</span>
            </div>
            {
              categorizedSlots && categorizedSlots.morning && categorizedSlots.morning.length > 0 ? (
                <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>
              {categorizedSlots.morning.map((time, index) => (
                <div className={`text-center py-2 rounded text-sm ${
                  showcolored && index === 0
                    ? 'bg-[--primary] text-white'
                    : selectedTime.category === 'morning' && selectedTime.index === index
                      ? 'activesbg'
                      : 'bg-white text-black'
                }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('morning', index)} key={index}>
                  <p>{time}</p>
                </div>
              ))}
            </div>
              ):(
                <div className="text-start  text-[--primary]">
          No time is available in this slot.
        </div>
              )
            }
           
          </div>

          <div className='flex flex-col '>
            <div className='flex flex-row space-x-2 items-center py-3'>
              <span>
                <img src={Image2} alt='img' className='text-xl'/>
              </span>
              <span className='font-semibold text-[#232426]'>Afternoon Slots</span>
            </div>
               {
                categorizedSlots && categorizedSlots.afternoon && categorizedSlots.afternoon.length > 0  ?(
                  <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>


{categorizedSlots.afternoon.map((time, index) => (
  <div className={` text-center py-2 rounded text-sm ${
    selectedTime.category === 'afternoon' && selectedTime.index === index
      ? 'activesbg'
      : ''
  }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('afternoon', index)} key={index}>
    <p>{time}</p>
  </div>
))}
</div>
                ):(
                  <div className="text-start  text-[--primary]">
          No time is available in this slot.
        </div>
                )

               }
           
          </div>

          <div className='flex flex-col '>
            <div className='flex flex-row space-x-2 items-center py-3'>
              <span>
                <img src={Image3} alt='img' className='text-xl'/>
              </span>
              <span className='font-semibold text-[#232426]'>Evening Slots</span>
            </div>
           {   categorizedSlots && categorizedSlots.afternoon && categorizedSlots.afternoon.length > 0 ? 
           (
            <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>
              {categorizedSlots.evening.map((time, index) => (
                <div className={` text-center text-black py-2 rounded text-sm ${
                  selectedTime.category === 'evening' && selectedTime.index === index
                    ? 'activesbg'
                    : ''
                }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('evening', index)} key={index}>
                  <p>{time}</p>
                </div>
              ))}
            </div>
           ):
           (
            <div className="text-start  text-[--primary]">
          No time is available in this slot.
        </div>
           )
           
           
           }
            {/* <div className='grid grid-cols-3 md:grid-cols-4 gap-5 cursor-pointer  '>
              {categorizedSlots.evening.map((time, index) => (
                <div className={` text-center text-black py-2 rounded text-sm ${
                  selectedTime.category === 'evening' && selectedTime.index === index
                    ? 'activesbg'
                    : ''
                }`} style={{ boxShadow: '0px 0px 6px 1px #00000040' }} onClick={() => handleTimeClick('evening', index)} key={index}>
                  <p>{time}</p>
                </div>
              ))}
            </div> */}
          </div>

        </div>
      </div>
         
        
      </>
   )

 }



export default Whichtime
