import React, { useState,useEffect } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './navbardeatil.css'

const Navbardeatil = () => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 0 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1 
    }
  };
    const [show, setShow] = useState(false);

    const [currentSection, setCurrentSection] = useState('Feedback');
    const handleScroll = () => {
      const sectionEls = document.querySelectorAll('.section');
      let currentSectionId = 'Feedback';
      sectionEls.forEach(sectionEl => {
        if (window.scrollY >= sectionEl.offsetTop - 50) {
          currentSectionId = sectionEl.id;
        }
      });
      setCurrentSection(currentSectionId);
    };
    const handleClickNav = (section,event) => {
      event.preventDefault();
      setCurrentSection(section);
      const sectionEl = document.getElementById(section);
      if (sectionEl) {
        window.scrollTo({
          top: sectionEl.offsetTop - 50,
          behavior: 'smooth'
        });
      }
    };
  
    const controlNavbar = () => {
      setShow(window.scrollY > 200);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', controlNavbar);
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);



  return (
      <>
    
      <div className={`w-full fixed top-0   bg-white ${show ? 'block' : 'hidden'}  cursor-pointer shadow-md border-b-[1px] z-50 `}>
      <div className='w-11/12 mx-auto py-4 '>
     <div className='block md:hidden'>
      <Carousel
          responsive={responsive}
          infinite={true}     
          axis='horizontal'
          showArrows={false}
            draggable={true}
            itemClass='carousel-item-padding-10'
removeArrowOnDeviceType={['desktop','tablet','mobile']}
 className='w-[95%] mx-auto flex flex-row text-[15px]  text-black font-semibold '
        >

        <div>
       <a href='#Feedback' onClick={(e)=>handleClickNav('Feedback',e)}  className={`transition-all duration-2000  hover:text-[--primary]  pb-6    font-semibold  ${currentSection === 'Feedback' ? 'active' : ''}`} > Feedback</a>
        </div>
        <div >
      <a   href='#ClinicDetails' onClick={(e)=>handleClickNav('ClinicDetails',e)}  className={`transition-all duration-2000 hover:text-[--primary]   pb-6 font-semibold  ${currentSection=== 'ClinicDetails' ? 'active' : ''}`}>Clinic Details</a>
        </div>
        <div > 
        <a href='#Services'  onClick={(e)=>handleClickNav('Services',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-6 font-semibold  ${currentSection === 'Services' ? 'active' : ''}`}>Services</a>
        </div>
        <div >
        <a href='#Education' onClick={(e)=>handleClickNav('Education',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-6 font-semibold  ${currentSection === 'Education' ? 'active' : ''}`} >Education </a>
        </div>
        <div > 
        <a href='#OtherInfo' onClick={(e)=>handleClickNav('OtherInfo',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-6 font-semibold  ${currentSection === 'OtherInfo' ? 'active' : ''}`} >Other Info</a>
        </div>
        <div >
        <a href='#Reviews' onClick={(e)=>handleClickNav('Reviews',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-6 font-semibold  ${currentSection === 'Reviews' ? 'active' : ''}`}>Reviews</a>
        </div>
        </Carousel>
        </div>
      

        <div
 className='w-full mx-auto hidden md:block    text-black font-semibold '
        >
    <ul className='flex flex-row text-[15px] space-x-7'>
    <li>
       <a href='#Feedback' onClick={(e)=>handleClickNav('Feedback',e)}  className={`transition-all duration-2000  hover:text-[--primary]  pb-4   font-semibold  ${currentSection === 'Feedback' ? 'active' : ''}`} > Feedback</a>
        </li>
        <li >
      <a   href='#ClinicDetails' onClick={(e)=>handleClickNav('ClinicDetails',e)}  className={`transition-all duration-2000 hover:text-[--primary]   pb-4 font-semibold  ${currentSection=== 'ClinicDetails' ? 'active' : ''}`}>Clinic Details</a>
        </li>
        <li > 
        <a href='#Services'  onClick={(e)=>handleClickNav('Services',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-4 font-semibold  ${currentSection === 'Services' ? 'active' : ''}`}>Services</a>
        </li>
        <li >
        <a href='#Education' onClick={(e)=>handleClickNav('Education',e)} className={`transition-all duration-2000 hover:text-[--primary]  pb-4 font-semibold  ${currentSection === 'Education' ? 'active' : ''}`} >Education </a>
        </li>
        <li > 
        <a href='#OtherInfo' onClick={(e)=>handleClickNav('OtherInfo',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-4 font-semibold  ${currentSection === 'OtherInfo' ? 'active' : ''}`} >Other Info</a>
        </li>
        <li >
        <a href='#Reviews' onClick={(e)=>handleClickNav('Reviews',e)} className={`transition-all duration-2000 hover:text-[--primary]   pb-4 font-semibold  ${currentSection === 'Reviews' ? 'active' : ''}`}>Reviews</a>
        </li>
    </ul>
       
        </div>

      </div>

      </div>

      </>
  )
}

export default Navbardeatil
