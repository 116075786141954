import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import Image1 from '../../../../../../../assests/Images/jpg/hqdefault (1).jpg'
import Image2 from '../../../../../../../assests/Images/jpg/hqdefault (2).jpg'
import Image3 from '../../../../../../../assests/Images/jpg/hqdefault (3).jpg'
import Image4 from '../../../../../../../assests/Images/jpg/hqdefault (4).jpg'
import Image5 from '../../../../../../../assests/Images/jpg/hqdefault (5).jpg'
import Image6 from '../../../../../../../assests/Images/jpg/hqdefault (6).jpg'
import Image7 from '../../../../../../../assests/Images/jpg/hqdefault (7).jpg'
import Image8 from '../../../../../../../assests/Images/jpg/hqdefault (8).jpg'
import Image9 from '../../../../../../../assests/Images/jpg/hqdefault (9).jpg'


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2 
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
      slidesToSlide: 1 
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 2,
      slidesToSlide: 1 
    },
    smallscreen:{
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };
  const CustomLeftArrow = ({ onClick}) => {
    return (
      <button onClick={onClick} className='bg-[#F2F2F2] bg-opacity-70 hover:bg-opacity-100 absolute top-28 left-0 z-30  h-[40px] w-[40px] rounded-full flex justify-center items-center shadow-md ' >
        <IoIosArrowBack className='text-black font-bold text-lg' />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button onClick={onClick} className='bg-[#F2F2F2] bg-opacity-40 hover:bg-opacity-100  absolute top-28 right-0 z-30 h-[40px] w-[40px] rounded-full flex justify-center items-center shadow-md '  >
        <IoIosArrowForward className='text-black font-bold text-lg' />
      </button>
    );
  };

const Latestvideos = () => {
  return (
      <>
         <div className='flex flex-col space-y-5 px-2 py-4  box  rounded-lg lg:shadow-none lg:rounded-none mt-5 lg:border-b-2 cursor-pointer bg-white'>
      <div className='flex flex-col space-y-1 px-1 '>
        <div className='flex flex-row justify-between items-center  '>
       <div className='text-[17px] font-medium text-[--primary]   leading-10'>Latest Videos</div> 
       <div className='text-sm cursor-pointer '>All Videos</div>
        </div>
         
         <Carousel 
                axis='horizontal'
            showStatus={false}
            responsive={responsive}
            customLeftArrow={ <CustomLeftArrow  />}
            customRightArrow={ <CustomRightArrow  />} 
            infinite={false}
            className='w-full  h-96 cursor-pointer  '>
          <div className=' flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-5 h-72 space-y-4 lg:space-y-3 relative  '>
          <div className='w-full'>
          <img src={Image1}  alt='src' className='rounded-md' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#686868]'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>



          <div className=' flex flex-col    w-60 md:w-52 lg:w-40  xl:w-48    mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative  '>
          <div className='w-full'>
          <img src={Image1}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Skin Care Secrets - Night Time Routine For All Skin
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#686868]'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>


          <div className=' flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative  '>
          <div className='w-full'>
          <img src={Image2}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Sanitary Pad Rashes - Periods Pad Se Hone Wale Rash
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#686868]'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>



          <div className=' flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative  '>
          <div className='w-full'>
          <img src={Image3}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>



          <div className='flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48 mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative '>
          <div className='w-full'>
          <img src={Image4}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Sanitary Pad Rashes - Periods Pad Se Hone Wale Rash
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>


          <div className='flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative  '>
          <div className='w-full '>
          <img src={Image5}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>


          <div className='flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative '>
          <div className='w-full'>
          <img src={Image6}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>


          <div className=' flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative '>
          <div className='w-full'>
          <img src={Image7}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
         
          
          </div>


          <div className='flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative '>
          <div className='w-full'>
          <img src={Image8}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
          
          </div>


          <div className=' flex flex-col    w-60 md:w-52 lg:w-40 xl:w-48  mx-9 md:mx-2 lg:mx-4 h-72 space-y-4 lg:space-y-3 relative '>
          <div className='w-full'>
          <img src={Image9}  alt='src' className=' rounded-md  ' />
          </div>
          <div className='text-sm font-medium leading-5 text-black '>
          Book an Appointment with Dr. Afshan Ahmad Best
          </div>
          <div className='absolute bottom-0 w-full'>
          <div className='text-xs text-[#46484B] text-opacity-90'>
          3 year(s) ago
          </div>
          </div>
          
          </div>



         
          </Carousel>
    
      </div>
    </div>
      </>
  )
}

export default Latestvideos
