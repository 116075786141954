import React from 'react'

const Experince = ({doctorExperince,doctoryearsexp}) => {
  return (
      <>
        <div className='flex flex-col space-y-5 px-2 py-4  box  rounded-lg lg:shadow-none lg:rounded-none mt-5 lg:border-b-2 cursor-pointer bg-white '>
           <div className='flex flex-col space-y-4 px-1'>
             <div className='text-[17px] font-semibold text-black   leading-12 '>
             Experience
             </div>
             <div className=' w-full'>
             <p className='text-[15px]'>{doctoryearsexp.yearsOfExperience}</p>
         <ul className='flex flex-col text-sm text-black space-y-3 '>
         
         {
          doctorExperince && doctorExperince.map((experince)=>(
            <li key={experince._id}>
            {experince.company}
            </li>
          ))
         }
         </ul>
        
         </div>
           </div>
        </div>
      </>
  )
}

export default Experince
