import React from 'react'
import Logo from '../../../assests/Images/globalpics/footericon.svg'
import Security from '../../../assests/Images/globalpics/image 48.svg'
import Payment from '../../../assests/Images/globalpics/image 49.svg'
import Customers from '../../../assests/Images/globalpics/image 50.svg'
import Online from '../../../assests/Images/globalpics/image 51.svg'
import { FaEnvelope } from "react-icons/fa";
import Footerlast from './footerlast'
import Googlestore from '../../../assests/Images/homepics/googleplay.svg';
import Applestore from '../../../assests/Images/homepics/appstore.svg'
const Footer = () => {
  return (
    <>
        <div className='w-full bg-[#333333]'>
         <div className='w-11/12 py-5 mx-auto '>
         <div className='flex flex-col space-x-0  md:flex-row md:flex-wrap lg:flex-nowrap  sm:space-x-0 justify-between py-1     md:py-3 '>
         <div  className=' flex flex-col space-y-3 py-2 w-full  md:w-1/2 lg:w-1/3 '  >
          <img src={Logo} alt='img' className='w-36 h-auto'/>
            <p className='text-white font-normal text-sm leading-6'>Book appointments with the best Doctors and Specialists such as Gynecologists, Skin Specialists, Child Specialists, Surgeons, etc. Avail test services such as MRI, CT scan, Ultrasound, X-Ray, etc. and Online Doctor Video Consultations all across Pakistan conveniently.</p>

         </div>
         <div className='py-1 md:py-3 flex flex-col justify-start md:justify-center lg:items-center w-full md:w-1/2  lg:w-1/3 ' >
         <p className='text-white text-lg leading-[26px]  md:px-10 lg:px-0'>Company</p>
         <ul className='text-sm cursor-pointer   py-6 flex flex-col space-y-4   md:px-10 ml-0 lg:ml-4  ' >
                <li className='text-white '>About us</li>
                <li className='text-white '>Privacy policy</li>
                <li className='text-white '>Contact us</li>
                <li className='text-white '>Terms of Use</li>
                <li className='text-white'>FAQs</li>

            </ul>
         </div>


         <div className='py-1 md:py-3 w-full md:w-4/5 lg:w-1/3  ' >
         <p className='text-white text-lg leading-[26px]'>Subscribe To Our
Newsletter</p>
  <div className='flex flex-row items-center  w-full h-10 my-6 rounded-md bg-white'>
   <input type='text' placeholder='Enter Your Email' className='w-[85%] h-full px-2 outline-none rounded-tl-sm  rounded-bl-sm'/>
   <button className='bg-[--primary] text-white w-[15%] h-full flex items-center justify-center  rounded-tr-sm  rounded-br-sm ' ><FaEnvelope/></button>
  </div>
  <div className='flex flex-row w-full space-x-1   cursor-pointer lg:px-[2px] xl:px-0'>
    <img src={Googlestore} alt='img' className='h-[50px] w-auto lg:w-1/2 xl:w-auto'/>
    <img src={Applestore} alt='img' className='h-[50px] w-auto lg:w-1/2 xl:w-auto'/>
  </div>
         </div>
        
        
         </div>
         <div className='grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 gap-4  py-3'>
         <div className='flex flex-row  space-x-3 items-center'>
          <img src={Security} alt='img' className='w-8 h-8'/>
          <div className='flex flex-col text-white'>
          <h2 className='text-base leading-7  font-medium'>Rvmp Verified Doctors</h2>
          <p className='text-sm font-normal text-white text-opacity-80'>Authentic & updated information</p>
          </div>
         </div>
         <div className='flex flex-row  space-x-3 items-center'>
          <img src={Payment} alt='img' className='w-8 h-8'/>
          <div className='flex flex-col text-white'>
          <h2 className='text-base leading-7 font-medium'>Money back guarantee</h2>
          <p className='text-sm font-normal text-white text-opacity-80'>We return money within 48 hours</p>

          </div>

         </div>
         <div className='flex flex-row  space-x-3 items-center'>
          <img src={Customers} alt='img' className='w-8 h-8'/>
          <div className='flex flex-col text-white'>
          <h2 className='text-base leading-7 font-medium'>12/7 customer support</h2>
          <p className='text-sm font-normal text-white text-opacity-80'>Well-trained & Supportive team</p>

          </div>

         </div>
         <div className='flex flex-row  space-x-3 items-center'>
          <img src={Online} alt='img' className='w-8 h-8'/>
          <div className='flex flex-col text-white'>
          <h2 className='text-base leading-7 font-medium'>Secure online payment</h2>
          <p className='text-sm font-normal text-white text-opacity-80 '>We possess SSL / Secure сertificate</p>

          </div>

         </div>

         </div>

      
          
         </div>
        </div>
        <Footerlast/>
    </>
  )
}

export default Footer
