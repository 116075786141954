import React, { useState } from 'react'
import { FaHospitalAlt } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { FaClock } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { FaHeadphones } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import {bookAppointment} from '../../../../../redux/actions/bookappoinmentactions';
import { useNavigate } from 'react-router-dom';
const Sidebar = ({doctorhospital,doctorsid}) => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
    const [expand,setExpand]=useState(false);
    const  handleExpand=(index)=>{
      setExpand(index)
    }
    const currentDate=new Date();
    const year=currentDate.getFullYear();
    const month=String(currentDate.getMonth()+1).padStart(2,'0');
    const day=String(currentDate.getDate()).padStart(2,'0');
    const date= `${year}-${month}-${day}`;

    const handleBookAppointment = (doctorhosp) => {
      console.log('Button clicked');
      console.log('Hospital ID:', doctorhosp._id);
      console.log('Doctor ID:', doctorsid._id);
      console.log('Date:', date);
      dispatch(bookAppointment({
        date,
        doctorId: doctorsid._id,
        hospitalAvailableId: doctorhosp._id,
        hospitalfee:doctorhosp.fee,
        hospitalname:doctorhosp.selectHospital.name,
        homeclinic:doctorhosp.homeCheckUp

      })).then(() => {
        console.log('Appointment booked successfully');
        navigate('/pages/appoinetment/')
      }).catch((error) => {
        console.error('Error booking appointment:', error);
      });
    };


  return (
      <>
        <div className='flex flex-col  space-y-7 py-10'>
        {/* box section */}
   {
    doctorhospital && doctorhospital.map((doctorhosp,index)=>(
      <div  className='flex flex-col space-y-5 px-2 py-4 box-shadow rounded-md cursor-pointer mt-6' key={doctorhosp._id}>
           <div className='flex flex-row  items-center space-x-2 px-1     '>
              <div className='text-[--primary]'>
              <FaHospitalAlt/>
              </div>
              <div className='text-[22px] font-medium leading-6'>
                    {doctorhosp.selectHospital.name}

              </div>
           </div>
           <div className=' flex flex-row justify-between px-2 py-1 border-b-[1px] text-sm '>
             <div className='text-black text-opacity-60'>Fee:</div>
             <div className=' text-black'>Rs. {doctorhosp.fee}</div>
           </div>
           <div className='flex flex-row justify-between px-2 py-1 border-b-[1px] text-sm '>
           <div className='text-black text-opacity-60'>Address:</div>
             <div className=' flex flex-row  font-medium items-center space-x-1 '>
             <span><IoLocation/></span> 
             <span className='underline'>{doctorhosp.selectHospital.address}</span>
             </div> 
           </div>
           <div className='flex flex-col  space-y-3' onClick={()=>handleExpand(index)}>
           <div className='flex flex-row justify-between px-2 py-1  text-sm'>
                <div className='flex flex-row items-center space-x-1 text-[#28B446]'>
                <span><FaClock/></span>
                <span> Available time  </span> 
                </div>
                <div className='flex flex-row items-center space-x-1 text-black' >
                   <span><IoIosArrowDown/></span>
                </div>
             </div>
              { 
            <div className='flex flex-col w-full space-y-2 transition-max-height duration-500  overflow-hidden px-2 text-black text-sm ' style={{ maxHeight:expand===index ?'1000px':'0'}}>
               {
    Object.keys(doctorhosp).filter(day => doctorhosp[day].status).map(day => (
      <div key={day} className='flex flex-row justify-between '>
        <div>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
        <div>
          {doctorhosp[day].availableRange.map(range => (
            <span key={range}>{range[0]} - {range[1]}</span>
          ))}
        </div>
      </div>
    ))
  }
            </div>
           } 
           </div>
              <button className='w-11/12 mx-auto rounded py-2 text-center text-base bg-[--primary] text-white' onClick={() => handleBookAppointment(doctorhosp)}>
                Book Appointment
              </button>
          
                

        </div>
     
    ))

   }

   
   


     
   


      



      
        {/* listling */}

      <div className='px-2 py-4'>
      <ul className='flex flex-col space-y-5'>
        <li className='flex flex-row space-x-5 items-center  text-sm '>
        <span><FaHeadphones/></span>
        <span>Priorty customer support</span>
        </li>

        <li className='flex flex-row space-x-5 items-center  text-sm '>
        <span><FaLock/></span>
        <span>100% secure</span>
        </li>
        

        <li className='flex flex-row space-x-5 items-center  text-sm '>
        <span><FaRegClock/></span>
        <span>Book Appointmet in 30 sec</span>
        </li>
      </ul>

      </div>


        </div>
      </>
  )
}

export default Sidebar
