import React from 'react'
import Healthparctice from '../../../assests/Images/jpg/provider-message-1232w___4b6fa.jpg'

const Paractices = () => {
  return (
    <>
      <div className='w-full '>
      <div className='w-11/12 py-1   mx-auto   '>
      <div className='flex flex-col sm:flex-row     py-4 md:py-6 lg:py-16 space-x-0 space-y-0 md:space-y-1 md:space-x-3  '>
      <div className='w-full md:w-2/4 mt-0 sm:mt-7 lg:mt-0'>
        <img src={Healthparctice} alt='img'/>
      </div>
      <div className='w-full md:w-2/4 p-5 '>
       <div className='flex flex-col'>
        <p className='text-lg leading-6 font-medium text-start'>Callvet for private practices</p>
        <h3 className='text-xl sm:text-2xl  leading-7 sm:leading-8 xl:leading-9 font-semibold py-2'>Are you a provider interested in reaching new patients?</h3>
        <ul className='flex flex-col space-y-2 lg:space-y-1  text-[15px] leading-6  py-3 font-light px-4 text-[#686868]'>
            <li className='list-disc'>Reach patients in your area looking for a new provider</li>
            <li className='list-disc'>Fill last-minute openings in your schedule</li>
            <li className='list-disc'>Strengthen your online reputation with verified reviews</li>
        </ul>
        <div className='py-3'>
        <button className='bg-[#4C63BD] text-white text-[15px] w-full px-0 md:w-auto md:px-4 py-2  box-shadow rounded hover:bg-opacity-90'>List Your Practice on Callvet</button>

        </div>
         
       </div>
      </div>

      </div>

      </div>
    </div>
    </>
  )
}

export default Paractices
