 import React from 'react'
 import Speclization from './information.jsx/speclization'
 import Experince from './information.jsx/experince'
 import Professional from './information.jsx/professional'
 import Latestvideos from './information.jsx/latestvideos'


const Otherinfo = ({doctorExperince,doctordegree,doctornamedeatil,doctorcategorey,doctorclincinformation,doctorservices}) => {
  return (
    <>
        <Speclization doctorservices={doctorservices}/>
        <Experince doctorExperince={doctorExperince}  doctoryearsexp={doctornamedeatil} />
        <Professional qulifiction={doctordegree}   dotorprofession={doctorcategorey}    doctorclincinformation={doctorclincinformation}    doctornamedeatil={doctornamedeatil} />
        <Latestvideos  />
       

    </>
  )
}

export default Otherinfo
