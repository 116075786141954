import React from 'react'

const Professional = ({qulifiction,doctornamedeatil,doctorclincinformation}) => {
 
  const firstFee = doctorclincinformation && doctorclincinformation.length > 0 
  ? doctorclincinformation[0].fee 
  : null;
const lastFee = doctorclincinformation && doctorclincinformation.length > 0 
  ? doctorclincinformation[doctorclincinformation.length - 1].fee 
  : null;
const salaryPackage = firstFee && lastFee ? `${firstFee} - ${lastFee}` : '';
    return (
      <>
       <div className='flex flex-col space-y-5 px-2 py-4  box  rounded-lg lg:shadow-none lg:rounded-none mt-5 lg:border-b-2 cursor-pointer  bg-white'>
           <div className='flex flex-col space-y-4 px-1'>
             {/* <div className='text-[17px] font-semibold text-black   leading-10 '>
             Professional Memberships
             </div> */}
             {/* <div className=' w-full'>
         <ul className='flex flex-col space-y-4 text-sm px-5'>
         <li className=' list-disc	 '>
         The Royal College of Physicians (RCP) London
         </li>
         <li className=' list-disc	 '>
         The Association of British Neurologists (ABN) UK
         </li>
         <li className=' list-disc	 '>
         The European Academy of Neurology (EAN)        
          </li>
         <li className=' list-disc	 '>
         Encephalitis Society UK       
           </li>
         </ul>
        
         </div> */}

         {/* <div className='text-[17px] font-semibold text-black   leading-10 '>
         Languages spoken           
           </div>
           <div className=' w-full'>
         <ul className='flex flex-col space-y-4 text-sm px-5'>
         <li className=' list-disc	 '>
         English
         </li>
         <li className=' list-disc	 '>
         Punjabi       
           </li>
         <li className=' list-disc	 '>
         Siraiki        
          </li>
         <li className=' list-disc	 '>
         Urdu         
         </li>
         </ul>
        
         </div> */}
         <div className='text-[17px] font-medium text-black   leading-10 '>
         About {doctornamedeatil.username}       
            </div>
         <ul className='text-sm'>
         <li >
         {doctornamedeatil.bio}
         </li>
         </ul>
         <div className='text-[17px] font-semibold text-black   leading-10 '>
         Qualifications      
             </div>
             <p className='text-sm'>{doctornamedeatil.username} has the following qualifications:</p>
             <div className=' w-full'>
         <ul className='flex flex-col space-y-4 text-sm px-5'>
         {
          qulifiction && qulifiction.map((qulify)=>(
            <li className=' list-disc	 ' key={qulify._id}>
             {qulify.degree}
         </li>
          ))
         }
         </ul>
        
         </div>
         

         <div className='text-[17px] font-semibold text-black   leading-10 '>
         Fees      
             </div>
             <p className='text-sm'> {doctornamedeatil.username} charges a between       for appointments.</p>
         <div className='text-[17px] font-semibold text-black   leading-10'>
         Availability    
             </div>
             {doctorclincinformation&& doctorclincinformation.map((hospital)=>(
               <div  className='flex flex-col space-y-1' key={hospital._id}>
               <p  className='text-sm'>{hospital.selectHospital.name}</p>
               <div className='flex flex-row text-sm space-x-2 '>
                 <div>Available days:</div>
                 <div className='flex flex-row  space-x-1'>
                 <p>{hospital.monday.status && 'mon'}</p>
               <p>{hospital.tuesday.status && 'tue'}</p>
               <p>{hospital.wednesday.status && 'wed'}</p>
               <p>{hospital.thursday.status && 'thu'}</p>
               <p>{hospital.friday.status && 'fri'}</p>
                <p>{hospital.saturday.status && 'sat'}</p>
                <p>{hospital.sunday.status && 'sun'}</p>
                 </div>
                </div>
               
               </div>
             ))}  
    

         {/* <div className='text-[17px] font-semibold text-black   leading-10'>
         Patient Feedback    
             </div> */}
          {/* <p className='text-sm'>{doctornamedeatil.username} has a 100% patient satisfaction score with 2155 verified patient reviews on Callvet</p> */}
            
          {/* <div className='text-[17px] font-semibold text-black   leading-10 '>
          Services Offered    
             </div> */}
             {/* <p className='text-sm'>Following are some of the services offered by Dr. Salman Farid:</p> */}

 {/* <ul className='flex flex-col space-y-4 text-sm px-5'>
         <li className=' list-disc	 '>
         ALS Treatment
         </li>
         <li className=' list-disc	 '>
         Carotid and Transcranial Doppler (TCD)     
              </li>
              <li className=' list-disc	 '>
              Electroencephalography (EEG)        
                    </li>
              <li className=' list-disc	 '>
              Electromyography (EMG)     
              </li>
              <li className=' list-disc	 '>
              Epilepsy Treatment     
              </li>
         </ul> */}
         
        
         
           </div>
        </div>
    </>
  )
}

export default Professional
