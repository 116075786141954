import React, { useState,useContext } from 'react'
import { RxCross2 } from "react-icons/rx";
import Logo from '../../../assests/Images/globalpics/Layer 1.svg'
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { PatientContext } from '../../../context/pateintcontext';
import { IoIosArrowDown } from "react-icons/io";
const Toolbars = ({setShow}) => {
  const{selectedPatient}=useContext(PatientContext);
  const [listshow,setListshow]=useState(false);
const closeNav=()=>{
    setShow(null);
}
const handleList=()=>{
  setListshow(!listshow)
}
  return (
    <>
    <div className=' block lg:hidden'>
    <div className='w-full h-full fixed top-0 left-0 z-50 ' style={{backgroundColor:'rgba(0,0,0,0.2)'}} >
         <div className='max-w-[90%] sm:max-w-72 md:max-w-96 h-screen      overflow-y-auto bg-[#F7F9FC]  z-50   ' >
<div className='sticky top-0   py-1 bg-[#F7F9FC]'>
<div className='flex flex-row items-center justify-between    w-11/12 mx-auto my-1'>
          <div className='w-36 h-auto  cursor-pointer'>
   <Link to='/'> <img src={Logo} alt='img' className='w-full h-full'/></Link>
    </div>
    <div className='text-black md:text-[26px] hover:bg-[#C9D0EB] hover:text-[--primary] px-1 py-1  cursor-pointer' onClick={closeNav} >
    <RxCross2/>
    </div>

          </div>
</div>

          <div className='flex  justify-start   h-36 my-3'>

          <div className='flex flex-col  space-y-3 p-3'>
          <p className='text-xl md:text-2xl  font-semibold '>Welcome to Callvet</p>
          {

selectedPatient  ? (
  <p className='flex space-x-2 items-center text-[--primary] text-[15px] font-semibold cursor-pointer  '> <span> {selectedPatient} </span> <span><IoIosArrowDown/></span> </p>
):(
  <button className='bg-[#4C63BD] text-white w-[230px] py-2 text-[15px]  rounded	 '>Login / Signup</button>
                        
)

}
        
          <button className='bg-[#28B446] text-white flex justify-center items-center space-x-2 w-[230px] py-2 rounded text-[15px]'>
              <span><FaPhoneAlt/></span>
              <span>04238900939</span>
          </button>

          </div>

          </div>
           
          <div className='w-full h-screen        bg-[#333333]'>
          <div className=' w-11/12 mx-auto'>
            <p className='text-xl text-white py-3'>Callvet</p>
            <ul className='text-base text-white text-opacity-85 flex flex-col space-y-4 cursor-pointer'>
                <li onClick={handleList} >Doctor</li>
                {
                  listshow &&
                  <ul className='text-sm space-y-3'>
              <Link to='/pages/doctor/'><li className='text-white text-opacity-60'>Animals Test</li></Link>    
                  <li className='text-white text-opacity-60' >Pets Surgery</li>
                  <li className='text-white text-opacity-60' >Birds Checkup</li>
                  <li className='text-white text-opacity-60' >Pets Grooming</li>
                  <li className='text-white text-opacity-60'>Pets Vaccination</li>
                  <li className='text-white text-opacity-60'>Pets Checkup</li>
                  <li  className='text-white text-opacity-60'>Domestic Animals</li>
                  </ul>

                }
                  
                <li>Health Blog</li>
               <Link to='/joinasdoctor'><li>Join as Doctor</li></Link>
                <li>Help</li>
            </ul>
          </div>
          </div>
         
       
    

         
          
        </div>
         </div>
    </div>
        
      
    

  
  
       
    </>
  )
}

export default Toolbars
