import React,{useState} from 'react'

const MorningSlots = ['09:00 AM', '09:20 AM', '09:40 AM', '10:00 AM', '10:20 AM', '10:40 AM', '11:00 AM', '11:20 AM', '11:40 AM'];
function TimeSlot({ time, setSelectedTime, selectedTime }) {
    const handleClick = () => {
        setSelectedTime(time);
    };
    return (
        <div onClick={handleClick} style={{ cursor: 'pointer', color: time === selectedTime ? 'red' : 'black' }}>
            {time}
        </div>
    );
}
const Testing = () => {
    const [selectedTime, setSelectedTime] = useState(null);
  return (
       <>
       <div>
            {MorningSlots.map((time, index) => (
                <TimeSlot key={index} time={time} setSelectedTime={setSelectedTime} selectedTime={selectedTime} />
            ))}
            <div>Selected Time: {selectedTime}</div>
        </div>

       </>
  )
}

export default Testing
