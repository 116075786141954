import { createSlice } from "@reduxjs/toolkit";
const VerifyloginReducer= createSlice({
   name:'verifylogin',
   initialState:{
        token:false,
        error:null,
        success:false,
        PhoneNumber:'',
         otp:''
   },
   reducers:{
    setVarifyLogin(state,action){
       state.token=action.payload;
       state.PhoneNumber=action.payload;
       state.otp=action.payload;
    },
    setSuccess(state,action){
      state.success=action.payload;
    },
    setError(state,action){
      state.error=action.payload;
    }
   }
})
export const { setVarifyLogin,setSuccess,setError}=VerifyloginReducer.actions;
export default VerifyloginReducer.reducer;


