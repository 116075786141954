import React from 'react'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
const Heading = ({doctorctiy,doctorcategories}) => {
  const doctorcate = doctorcategories.map((categories) => (
    <div key={categories.name}>
      <p>{categories.name}</p>
    </div>
  ));


  return (
      <>
        <div className='w-full '>
             <div className='w-11/12 mx-auto  px-2 flex flex-col  py-3 '>
             <div className='py-1'>
                 <ul className='text-[10px] sm:text-xs flex space-x-1 sm:space-x-2 cursor-pointer uppercase'>
                    <li className='flex items-center space-x-1'><span className='text-primary'>Home</span><span className='text-[#D4D7D9]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center space-x-1'><span className='text-primary'>Pakistan</span> <span className='text-[#D4D7D9]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center space-x-1'>  <span className='text-primary'>{doctorctiy}</span><span className='text-[rgb(212,215,217)]'><MdOutlineKeyboardArrowRight/></span></li>
                     <li className='flex items-center ' ><span>{doctorcate}</span></li>
                 </ul>
               </div>
             <div className='py-3 sm:py-1 w-full '>
                  <div className=' flex flex-row space-x-2 font-semibold text-xl md:text-2xl leading-8  '>
                  <div >
                  The Best 
                  </div>
                  <div>
                  {doctorcate} 
                  </div>
                  <div>
                  in  {doctorctiy}
                  </div>
                  </div>
               </div>
               
            
             </div>
        </div>
      </>
  )
}

export default Heading
