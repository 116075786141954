import React from 'react'
import { useSelector } from 'react-redux';
import Navbar from '../../components/global/navbar'
import Doctorname from '../../components/pages/appoinetment/doctorname'
import Statisfied from '../../components/pages/appoinetment/statisfied'
// import Doctorreviws from '../../components/pages/appoinetment/doctorreviws'
import Bookingtime from '../../components/pages/appoinetment/bookingtime'
import Selectbooknav from '../../components/pages/appoinetment/selectbooknav'
import Footer from '../../components/global/footer'
import Stickyconfirm from '../../components/pages/appoinetment/stickyconfirm'
import Headroom from 'react-headroom'
const Appoinetment = () => {

  const {username,image, hospitalfee,hospitalname,slots,doctorId,hospitalAvailableId,loading,homeclinic} = useSelector((state) => state.appointment);

  return (
      <>
      <div className='bg-[#F8F9FA]'>
      <div className='block md:hidden'>
        <Selectbooknav/>
      </div>
      <Headroom className='' >
      <div className='navshadow z-50 bg-white  hidden md:block  '>
      <Navbar/>
      </div>
      </Headroom>
      <div className='w-full '>
      <Doctorname  username={username} image={image}  fee={hospitalfee} hospitalselected={hospitalname}  homeclinic={homeclinic} />
      <Bookingtime slots={slots}   doctorId={doctorId}   hospitalAvailableId={hospitalAvailableId} loading={loading}  hospitalfee={hospitalfee} hospitalname={hospitalname}   />
      <Statisfied />
      </div>
      <Footer/>
      <Stickyconfirm  />
      </div>
      
     
      </>
  )
}

export default Appoinetment
