import {setCityList,setLoading} from '../reducers/citylistreducer';
import axios from 'axios';

export const fetchapicities=()=>async(dispatch)=>{
    try{
        dispatch(setLoading(true));
        const response =await axios.get('https://callvet.raabtastore.com/api/setting/city')
        dispatch(setCityList(response.data.data.docs))
    }
    catch(error){
        console.log('Error are showing',error)
    }
    finally{
        dispatch(setLoading(false));
    }

}